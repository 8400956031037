import React from "react";

// mui
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
	h2: {
		color: theme.palette.linen.primary,
	},
}));

function TopProduct(props) {
	const classes = useStyles();

	if (props.loading) {
		return (
			<div>
				<Skeleton animation="wave" variant="text" width={100} />
				<Skeleton
					animation="wave"
					variant="rect"
					width={250}
					height={80}
				/>
			</div>
		);
	}

	return (
		<div className="pt-1">
			<h5>{props.label}</h5>
			<h3 className={classes.h2}>{props.name}</h3>
			<p>{props.otherData}</p>
		</div>
	);
}

export default TopProduct;
