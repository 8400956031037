import React from "react";
import ImageUploading from "react-images-uploading";

import { uploadImage } from "../../services/private/ServicesService";

function ImageUploader({ serviceDetails, handleClick }) {
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  const upload = async () => {
    const payload = {
      id: serviceDetails.id,
      location: "service",
      image: images[0].data_url,
    };

    handleClick(images);

    setTimeout(() => {
      setImages([]);
    }, 2500);
  };

  return (
    <div className="App">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <div
              style={{
                border: "1px solid #ccc",
                borderStyle: "dashed",
                textAlign: "center",
              }}
            >
              <button
                style={{
                  opacity: isDragging ? 1 : 0.3,
                  border: "none",
                  width: "100%",
                  padding: "50px 0",
                  backgroundColor: isDragging && "#bbb",
                }}
                onClick={onImageUpload}
                {...dragProps}
              >
                Click or Drag & Drop
              </button>
            </div>

            {imageList.length > 0 && (
              <div className="mt-3">
                <button
                  className="btn btn-sm btn-primary mr-3"
                  onClick={upload}
                >
                  Start Uploading
                </button>

                <button
                  className="btn btn-sm btn-danger"
                  onClick={onImageRemoveAll}
                >
                  Remove all images
                </button>
              </div>
            )}

            {imageList.length > 0 && (
              <div className="row image-uploader">
                {imageList.map((image, index) => (
                  <div key={index} className="col-4 image-item" draggable>
                    <img src={image["data_url"]} />

                    <div className="image-item__btn-wrapper">
                      <button
                        className="text-danger image-uploader-button"
                        onClick={() => onImageRemove(index)}
                      >
                        <i class="bi bi-trash3"></i>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}

export default ImageUploader;
