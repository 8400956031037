import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { get, isEmpty, find, findIndex } from "lodash";
import { connect } from "react-redux";
import moment from "moment";

// custom imports
import { checkHoldingAmountAvailability } from "../../services/private/BookingService";
import AlertBar from "../core/Alert/AlertBar";
import InputText from "../core/InputText/InputText";
import InputSelect from "../core/InputSelect/InputSelect";
import CustomLoader from "../CustomLoader";

//nWrcTdj6

// This is a cart component
const BookedListTable = ({
  isAdmin,
  onClose,
  clearSlots,
  removeSlots,
  slots,
  discounts,
  couponsProps,
  onCheckPromoCode,
  proceedNext,
  membership,
  holdingAmountData,
  ...props
}) => {
  const [promoCode, setPromoCode] = useState();
  const [coupons, setCoupons] = useState(couponsProps);
  const [usedPoints, setPoints] = useState(0);
  const [tempCount, setTempCount] = useState(0);
  const [error, setError] = useState("");
  const [userHaveDiscount, setUserHaveDiscount] = useState(false);
  const [couponError, setCouponError] = useState({
    error: false,
    msg: "",
  });

  const clearCouponErrorState = () => setCouponError({ error: false, msg: "" });

  const [grandTotal, setGrandTotal] = useState(0.0);

  let totalCouponDiscount = 0.0;
  let totalMultiHoursDiscount = 0.0;
  let _totalCouponDiscountPrice = 0;
  let _totalCouponDiscountPriceForId = 0;

  let totalPrice = 0.0; //  mean with groping discount
  var totalTax = 0.0;
  let totalPriceWithoutDiscount = 0.0;
  let _subTotals = [];
  let currentServiceTax = 0;
  let currentServiceTaxPercentage = 0;

  let couponAppliedToOneOfTheService = false;

  useEffect(() => {
    setCoupons(couponsProps);
  });

  useEffect(() => {
    if (totalMultiHoursDiscount > 0) setUserHaveDiscount(true);
  }, [totalMultiHoursDiscount]);

  useEffect(() => {
    // console.log(`isAdmin => `, isAdmin);
    // console.log(`holdingAmountData => `, holdingAmountData);
    setTimeout(() => {
      if (holdingAmountData.holdAmount > 0) {
        Swal.fire(
          "Good job!",
          `We found $${holdingAmountData.holdAmount} in your holding. We will apply to this current cart`,
          "success"
        );
      }
    }, 1000);
  }, []);

  const clearBooked = async () => {
    clearSlots();
    onClose();
  };

  const remove = (slot, service_id) => {
    if (slots[0].slots.length === 1) {
      onClose();
    }
    removeSlots(slot, service_id);
  };

  const discountPercentage = (count, service_id) => {
    let percentage = 0;

    discounts.forEach((discount) => {
      // count is a total number hours which is total number of services
      if (
        discount.start <= count &&
        count <= discount.end &&
        discount.service_id === service_id
      ) {
        percentage = discount.discount;
        return percentage;
      }
    });

    return percentage;
  };

  const calculatePrice = (item, serviceSlots) => {
    // console.log(`3. calculating the price. . .`);
    // console.log(`Item => `, item);
    // console.log(`ServiceSlot => `, serviceSlots);

    let price = 0;
    let couponDiscount = 0;

    item.data.forEach((data) => {
      price = price + data.shift.actual_price;
    });

    // console.log(`price => `, price);

    const discount = discountPercentage(
      item.data.length,
      serviceSlots.service.id
    );

    // console.log(`Discount => `, discount);

    const total = price - (price * discount) / 100;

    // console.log(`Total => `, total);

    // console.log(`Check if there any coupon added`);
    if (coupons.length > 0) {
      couponDiscount = calculateCouponDiscountPrice(total, serviceSlots);
      totalCouponDiscount = totalCouponDiscount + couponDiscount;
      // alert(totalCouponDiscount);
    }

    // console.log(`no coupons`);

    totalPriceWithoutDiscount = totalPriceWithoutDiscount + price;
    // console.log(`total price without discount => `, totalPriceWithoutDiscount);
    totalMultiHoursDiscount =
      totalMultiHoursDiscount + (price * discount) / 100;
    // console.log(`total multi hour discount => `, totalMultiHoursDiscount);
    totalPrice = totalPrice + total - couponDiscount;
    // console.log(`total price => `, totalPrice);

    return (total - couponDiscount).toFixed(2);
  };

  const calculateSubTotal = (item, serviceSlots) => {
    // console.log(`2. calculating the sub total. . .`);
    let price = 0;
    item.data.forEach((data) => {
      price = price + data.shift.actual_price;
    });

    const discount = discountPercentage(
      item.data.length,
      serviceSlots.service.id
    );
    const total = price - (price * discount) / 100;

    console.log(`calculateSubTotal`, total);
    return total.toFixed(2);
  };

  const calculateDiscountPrice = (item, serviceSlots) => {
    // console.log(`1 calculating the discount price. . .`);
    let price = 0;
    item.data.forEach((data) => {
      price = price + data.shift.actual_price;
    });

    const discount = discountPercentage(
      item.data.length,
      serviceSlots.service.id
    );
    const discountPrice = (price * discount) / 100;
    if (discountPrice === 0) {
      return false;
    }
    return discountPrice.toFixed(2);
  };

  const calculateCouponDiscountPrice = (sub_total, serviceSlots) => {
    console.log(`calculateCouponDiscountPrice`, serviceSlots);
    _subTotals.push(parseFloat(sub_total));

    if (isEmpty(get(serviceSlots, "service", {}))) {
      return;
    }

    const { user } = props;

    if (isEmpty(coupons)) {
      return false;
    }

    let discountPercentage = 0;
    let discountPrice = 0;
    let totalDiscountPrice = 0;
    /* 
    const validCouponsAll = coupons.filter(
      (coupon) =>
        (isEmpty(coupon.member_ship_ids) ||
          coupon.member_ship_ids.includes(user.member_ship.id)) &&
        (isEmpty(coupon.user_ids) || coupon.user_ids.includes(user.id)) &&
        coupon.branch_ids.includes(
          get(serviceSlots, "service.branch_id", false)
        ) &&
        coupon.service_ids.includes(get(serviceSlots, "service.id", true));
    );
    */

    // console.log(`discountPrice => `, discountPrice);
    // console.log(`totalDiscountPrice => `, totalDiscountPrice);

    // console.log(`serviceSlots => `, serviceSlots);

    const validCouponsAll = coupons.filter((coupon) => {
      let userIdIncluded = false;
      let branchIdIncluded = false;
      let membershipIdIncluded = false;
      let serviceIdIncluded = false;

      let couponHaveUserIds = false;
      let couponHaveBranchIds = false;
      let couponHaveMembershipIds = false;
      let couponHaveServiceIds = false;
      // 1. Check user have discount or not
      // 2. Check override value is on or off
      /*if (userHaveDiscount && !coupon.is_override) {
        // if coupon error already set just return
        // if you remove this check you will have infinite render error
        if (couponError.error) return;
        // set the error
        setCouponError({
          error: true,
          msg: "You cannot use this coupon because you already have a discount.",
        });

        return;
      }*/

      if (coupon.no_of_used >= coupon.max_no_of_use) {
        if (couponError.error) return;
        // set the error
        setCouponError({
          error: true,
          msg: `Coupon has been reached the maximum limit`,
        });
        return;
      }

      if (!coupon.is_override && userHaveDiscount) {
        // if coupon error already set just return
        // if you remove this check you will have infinite render error
        if (couponError.error) return;
        // set the error
        setCouponError({
          error: true,
          msg: "You cannot use this coupon because you already have a discount.",
        });

        return;
      }

      if (coupon.over_value > 0 && grandTotal < coupon.over_value) {
        if (couponError.error) return;
        // set the error
        setCouponError({
          error: true,
          msg: `Your Grand Total must be at least ${coupon.over_value} to apply this coupon`,
        });
        return;
      }

      /*
       * Check user ids[] includes current user id
       * Check does promo branch ids[] include current cart branch id
       * Check does promo service ids[] include current cart service id
       * return the coupon
       */
      /* if (
        coupon.user_ids.includes(user.id) &&
        coupon.branch_ids.includes(
          get(serviceSlots, "service.branch_id", false)
        ) &&
        coupon.service_ids.includes(get(serviceSlots, "service.id", true))
      ) {
        console.log(`Current userId is included in userids`);
        console.log(`Current branchId is included in Promo BranchID`);
        console.log(`Current serviceId is included in Promo ServiceId`);

        return coupons;
      } else {
        console.log(`3rd check failed`);
      } */

      // console.log(`coupon.user_ids => `, coupon.user_ids.length);
      // console.log(`coupon.branch_ids => `, coupon.branch_ids.length);
      // console.log(`coupon.service_ids => `, coupon.service_ids.length);

      // coupon have user ids
      if (coupon.user_ids.length > 0) {
        couponHaveUserIds = true;
        // check user id included or not
        if (coupon.user_ids.includes(user.id)) {
          // console.log(`useriddddddddd => `, user.id);
          userIdIncluded = true;
        } else {
          let msg = "You cannot use this coupon.";
          if (!couponError.error && couponError.msg !== msg) {
            setCouponError({
              error: true,
              msg,
            });

            return;
          }
        }
      }
      // coupon have branch ids
      if (coupon.branch_ids.length > 0) {
        couponHaveBranchIds = true;
        // check branch id included or not
        if (
          coupon.branch_ids.includes(
            get(serviceSlots, "service.branch_id", false)
          )
        ) {
          /*console.log(
            `branchiddddddddddddddd => ${get(
              serviceSlots,
              "service.branch_id",
              false
            )} => `,
            coupon.branch_ids
          );*/
          branchIdIncluded = true;
        } else {
          let msg = "You cannot use this coupon at current branch.";
          if (!couponError.error && couponError.msg !== msg) {
            setCouponError({
              error: true,
              msg,
            });

            return;
          }
        }
      }

      // console.log(`after`);

      // coupon have membership ids
      if (coupon.member_ship_ids.length > 0) {
        couponHaveMembershipIds = true;
        // check membership id included or not
        // console.log(`user.member_ship_id`,user);
        if (coupon.member_ship_ids.includes(user.member_ship.id)) {
          /*console.log(
            `memberahip idddddddddddd => ${get(
              serviceSlots,
              "service.branch_id",
              false
            )} => ${user.member_ship.id} => `,
            coupon.member_ship_ids
          );*/
          membershipIdIncluded = true;
        } else {
          let msg = "Your membership is not eligible for the coupon.";
          if (!couponError.error && couponError.msg !== msg) {
            setCouponError({
              error: true,
              msg,
            });
          }

          return;
        }
      }

      /*console.log(
        `service iddddddddddd => ${get(serviceSlots, "service.id", true)} => `,
        coupon.service_ids
      );*/

      // coupon have service ids
      if (coupon.service_ids.length > 0) {
        couponHaveServiceIds = true;
        // check service id included or not
        // console.log(`true`);
        if (
          coupon.service_ids.includes(get(serviceSlots, "service.id", true))
        ) {
          // console.log(`?`);
          /*console.log(
            `service iddddddddddd => ${get(
              serviceSlots,
              "service.id",
              true
            )} => `,
            coupon.service_ids
          );*/
          serviceIdIncluded = true;
          couponAppliedToOneOfTheService = true;
        } else {
          let msg = "Your cart may doesn't have eligible studios.";
          if (!couponError.error && couponError.msg !== msg) {
            if (couponAppliedToOneOfTheService) {
              msg = `Coupon cannot be applied to ${serviceSlots.service.name}`;
            }

            setCouponError({
              error: true,
              msg,
            });

            return;
          }
        }
      }

      console.log(` `);
      console.log(`couponHaveUserIds => `, couponHaveUserIds);
      console.log(`couponHaveMembershipIds => `, couponHaveMembershipIds);
      console.log(`couponHaveServiceIds => `, couponHaveServiceIds);
      console.log(`couponHaveBranchIds => `, couponHaveBranchIds);
      console.log(` `);

      console.log(`userIdIncluded => `, userIdIncluded);
      console.log(`branchIdIncluded => `, branchIdIncluded);
      console.log(`membershipIdIncluded => `, membershipIdIncluded);
      console.log(`serviceIdIncluded => `, serviceIdIncluded);

      if (
        couponHaveMembershipIds &&
        membershipIdIncluded &&
        couponHaveUserIds &&
        !userIdIncluded
      ) {
        let msg = "Your account is not eligible for this coupon.";
        if (!couponError.error && couponError.msg !== msg) {
          setCouponError({
            error: true,
            msg,
          });
        }

        return;
      }

      /*if (
        couponHaveUserIds &&
        couponHaveBranchIds &&
        couponHaveMembershipIds &&
        couponHaveServiceIds &&
        userIdIncluded &&
        branchIdIncluded &&
        membershipIdIncluded &&
        serviceIdIncluded
      ) {
        return coupon;
      }*/

      if (
        !couponHaveBranchIds &&
        userIdIncluded &&
        !branchIdIncluded &&
        !couponHaveServiceIds
      ) {
        console.log(`user included but no branch included => `, coupon);
        return coupon;
      } else {
        if (!couponError.error && !branchIdIncluded && couponHaveBranchIds) {
          setCouponError({
            error: true,
            msg: "You cannot use this coupon at for this branch",
          });

          return;
        } else if (
          !couponError.error &&
          !serviceIdIncluded &&
          couponHaveServiceIds
        ) {
          if (coupon.service_ids.length >= 1) {
            setCouponError({
              error: true,
              msg: "Your cart doesn't have the eligible service",
            });

            return;
          } else {
            setCouponError({
              error: true,
              msg: "You cannot use this coupon for the service in your cart",
            });

            return;
          }
        }
      }

      if (
        !couponHaveServiceIds &&
        userIdIncluded &&
        !serviceIdIncluded &&
        !couponHaveBranchIds
      ) {
        console.log(`user included but no service id included => `, coupon);
        return coupon;
      }

      /*  if (
        (userIdIncluded && branchIdIncluded) ||
        (userIdIncluded && serviceIdIncluded)
      ) {
        console.log(`user and branch or service => `, );
        return coupon;
      } */

      if (userIdIncluded && branchIdIncluded && !couponHaveServiceIds) {
        console.log(`user and branch included , no service=> `, coupon);
        return coupon;
      }

      if (userIdIncluded && serviceIdIncluded && !couponHaveBranchIds) {
        console.log(`user and service included , no branch=> `, coupon);
        return coupon;
      }

      if (
        !couponHaveUserIds &&
        couponHaveMembershipIds &&
        membershipIdIncluded &&
        serviceIdIncluded
      ) {
        // if (couponError.error) {
        //   clearCouponErrorState();
        // }
        console.log(`membershipIdIncluded & serviceIdIncluded`);
        return coupon;
      }

      if (
        !couponHaveUserIds &&
        !userIdIncluded &&
        !couponHaveMembershipIds &&
        !membershipIdIncluded &&
        !couponHaveBranchIds &&
        serviceIdIncluded
      ) {
        console.log(`service id included and others are not included`, coupon);
        return coupon;
        // return coupon;
      }

      /*if (
        !couponHaveUserIds &&
        !userIdIncluded &&
        (branchIdIncluded || serviceIdIncluded)
      ) {
        console.log(
          `branch id and service id included and user not => `,
          coupon
        );
        return coupon;
        // return coupon;
      }*/

      console.log(`did pass any condition`, couponError);

      if (couponError.error) {
        console.log(`returning emprty []`);
        return;
      }

      console.log(`Final return`);

      return coupon;

      // console.log(`after membership check`);
      /*
       * Check membership ids with current user id and user ids should be an empty array
       * If it is true, current user eligible to use the promo
       * return the coupon
      //  */
      // if (
      //   coupon.member_ship_ids.includes(user.member_ship.id) &&
      //   isEmpty(coupon.user_ids)
      // ) {
      //   console.log(`coupon => `, coupon);
      //   // console.log(`Current membership ids have current user membership id and user_ids is empty`);
      //   // return coupons;
      // } else {
      //   console.log(`2nd check failed`);
      // }

      // console.log(`after member_ship_ids.includes(user.member_ship.id)`);
    });

    // console.log(`validCouponsAll`, validCouponsAll);

    if (validCouponsAll.length > 0) {
      validCouponsAll.forEach((coupon) => {
        if (coupon.off_percentage !== null) {
          discountPercentage = discountPercentage + coupon.off_percentage;
        }

        if (coupon.off_amount !== null) {
          discountPrice = discountPrice + coupon.off_amount;
        }
      });

      if (discountPercentage !== 0) {
        totalDiscountPrice =
          totalDiscountPrice + (sub_total * discountPercentage) / 100;
      }

      if (discountPrice !== 0) {
        totalDiscountPrice = totalDiscountPrice + discountPrice;
      }

      _totalCouponDiscountPrice = parseFloat(totalDiscountPrice);

      if (_totalCouponDiscountPrice > 0) {
        _totalCouponDiscountPriceForId = serviceSlots.service.id;
      }

      return totalDiscountPrice;
    }
    return false;
  };

  const calculateTaxes = (item, serviceSlots) => {
    console.log(`4. calulating the tax. . .`, serviceSlots);
    let price = 0;
    item.data.forEach((data) => {
      price = price + data.shift.actual_price;
    });

    const discount = discountPercentage(
      item.data.length,
      serviceSlots.service.id
    );

    console.log(`discount`, discount);

    console.log(`Price `, price);

    const _priceDiscount = ((price / 100) * discount).toFixed(2);

    if (_totalCouponDiscountPriceForId == serviceSlots.service.id) {
      console.log(`hmm`);
      price = price - _totalCouponDiscountPrice;
    }

    console.log(`Price after discount `, price);

    console.log(`_priceDiscount`, _priceDiscount);
    const total = price - _priceDiscount;

    console.log(`total`, total);

    totalTax = totalTax + (total / 100) * serviceSlots.service.tax;

    console.log(`totalTax`, totalTax);

    if (usedPoints > 0) {
      let tempTotal = total - usedPoints;
      totalTax = (tempTotal / 100) * serviceSlots.service.tax;
    }

    const taxPrice = (total / 100) * serviceSlots.service.tax;

    console.log(`taxPrice`, taxPrice);

    return taxPrice.toFixed(2);
  };

  const calculateGrantTotal = () => {
    // console.log(`5.calculating the grand total. . .`);

    let price = totalPrice;

    // no points used, we must deduct the used points
    if (usedPoints > 0) {
      price = totalPrice - usedPoints;
    }

    price = price + totalTax;

    if (grandTotal <= 0) {
      setGrandTotal(price);
    }

    if (holdingAmountData.holdAmount > 0) {
      let _amount = price - holdingAmountData.holdAmount;
      return _amount.toFixed(2);
    }

    return price.toFixed(2);
  };

  const checkPromoCode = () => {
    if (!promoCode) {
      // if coupon error already set just return
      // if you remove this check you will have infinite render error
      if (couponError.error) return;
      // set the error
      setCouponError({
        error: true,
        msg: "Cannot be empty.",
      });

      return;
    }

    setCouponError({
      error: false,
      msg: "",
    });

    onCheckPromoCode(promoCode);
  };

  const onChangePromoCode = (event) => {
    setPromoCode(event.target.value);
  };

  const onChangePoints = (event) => {
    // Restrict user from entering more than their points
    if (get(props.user, "total_points", null) < event.target.value) return;
    console.log(`here`);
    // Restrict user from entering negative values
    if (0 > event.target.value) return;

    const grant_total = totalPrice + totalTax - totalCouponDiscount;

    // Restrict user from entering more than grand total
    if (grant_total < event.target.value) return;

    setPoints(event.target.value);
    setError(null);

    const max = parseFloat(grant_total.toFixed(2));

    if (
      max >= event.target.value &&
      get(props.user, "total_points", null) >= event.target.value &&
      0 <= event.target.value
    ) {
      setError(null);
    } else {
      setError("Points should be in between your points and grand total");
    }
  };

  const nextProcess = (_data) => {
    setError("");
    const max = parseFloat(calculateGrantTotal() - usedPoints);
    // console.log(`when i am here => `);
    const total_discount =
      totalCouponDiscount + totalMultiHoursDiscount + parseFloat(usedPoints);
    let data = {
      holdingAmountData,
      totalPriceWithoutDiscount,
      grant_total: calculateGrantTotal(),
      totalPrice,
      tax: totalTax.toFixed(2),
      total_discount: total_discount.toFixed(2),
      coupon_discount: totalCouponDiscount,
      promo_code_id: coupons
        ? coupons.map((coupon) => {
            return coupon.id;
          })
        : null,
      used_points: parseFloat(usedPoints),
      isAdmin: _data.isAdmin,
      notes: formik.values.notes,
    };

    if (_data.isAdmin) {
      data = {
        ...data,
        ..._data,
      };
    }

    // console.log(`data => `, data);
    // return;

    // console.log(`mem => `, isEmpty(membership));

    proceedNext(error, data);
    return;

    // Below this are unwanted in my view
    // Whatever, I kept this

    if (isEmpty(membership)) {
      // console.log(`inside if => `);
      // proceedNext(error, data);
      return;
    }
    // console.log(`here? => `);
    if (
      max >= usedPoints &&
      get(props.user, "total_points", null) >= usedPoints &&
      0 <= usedPoints
    ) {
      // proceedNext(error, data);
    } else {
      setError("Points should be in between your points and grand total");
    }
  };

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertErrorMsg, setAlertErrorMsg] = useState("Something went wrong");
  const [isLoading, setIsLoading] = useState(true);

  const shootTypes = [
    { label: "Boudoir", value: "boudoir" },
    { label: "Fashion", value: "fashion" },
    { label: "Family", value: "family" },
    { label: "Newborn", value: "newborn" },
    { label: "Other", value: "other" },
    { label: "Portrait", value: "portrait" },
    { label: "Wedding", value: "wedding" },
  ];

  const formik = useFormik({
    initialValues: {
      customerName: get("", "customerName", ""),
      shootType: get("", "shootType", ""),
      notes: get("", "notes", ""),
    },
    validationSchema: Yup.object({
      customerName: Yup.string()
        .min(4, "name must be at least 4 characters")
        .required("Required"),
      // shootType: Yup.string().required("Required"),
    }),

    onSubmit: async (values) => {
      // console.log(`values`, values);

      if (values.shootType === "" || values.shootType === "default") {
        Swal.fire({
          title: "Error",
          text: "You must select a shoot type!",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#f53535",
          confirmButtonText: "Okay",
        });

        return;
      }

      nextProcess({ ...values, isAdmin: true });

      setIsLoading(true);
    },
  });

  return (
    <div className="show_shopping_cart">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              <b>
                <i className="fa fa-shopping-cart"></i> Your Cart
              </b>
              <i
                onClick={() => clearBooked()}
                data-toggle="tooltip"
                title="Clear all"
                style={{ padding: "0 20px", cursor: "pointer" }}
                className="fa fa-eraser  vm_text_red"
              ></i>
            </h4>
            <button
              onClick={() => onClose && onClose()}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <div
              style={{
                maxHeight: "calc(115vh - 500px)",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {slots &&
                slots.map((serviceSlots) => {
                  // console.log(serviceSlots);
                  return serviceSlots.groupByDate.map((item, index) => {
                    return (
                      <div className="cart">
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div className="col-6 item text-left">
                            <h4 className="vm_text_green">
                              <b>{get(serviceSlots, "service.name", "")}</b>
                            </h4>
                          </div>
                          <div className="col-6 item text-left">
                            <h5 className="vm_text_green">
                              <b>{item.date}</b>({item.data.length})
                            </h5>
                          </div>
                        </div>

                        {item.data.map((slot) => {
                          return (
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div className="col-7 item">
                                <p>
                                  {moment(
                                    slot.slot,
                                    "YYYY-MM-DD HH:mm:ss"
                                  ).format("hh:mm:A")}
                                </p>
                              </div>
                              <div
                                className="col-2 item text-right"
                                style={{ textDecoration: "line-through" }}
                              >
                                ${slot.shift.price}
                              </div>
                              <div className="col-2 item text-right">
                                ${slot.shift.actual_price}
                              </div>
                              <div
                                className="col-1 item text-right"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  remove(slot.slot, serviceSlots.service.id)
                                }
                              >
                                <i className="fa fa-times vm_text_red"></i>
                              </div>
                            </div>
                          );
                        })}

                        {calculateDiscountPrice(item, serviceSlots) && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              fontWeight: "bold",
                            }}
                          >
                            <div className="col-6 item text-left">
                              Multi hours discounts(
                              {discountPercentage(
                                item.data.length,
                                serviceSlots.service.id
                              )}
                              %)
                            </div>
                            <div className="col-5 item text-right">
                              -${calculateDiscountPrice(item, serviceSlots)}
                            </div>
                            <div className="col-1 item text-right"></div>
                          </div>
                        )}

                        {/*coupon discounts*/}
                        {calculateCouponDiscountPrice(
                          calculateSubTotal(item, serviceSlots),
                          serviceSlots
                        ) && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              fontWeight: "bold",
                            }}
                          >
                            <div className="col-6 item text-left">Coupon</div>
                            <div className="col-5 item text-right">
                              -$
                              {calculateCouponDiscountPrice(
                                calculateSubTotal(item, serviceSlots),
                                serviceSlots
                              )}
                            </div>
                            <div className="col-1 item text-right"></div>
                          </div>
                        )}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            fontWeight: "bold",
                          }}
                        >
                          <div className="col-6 item text-left">
                            Sub total($)
                          </div>
                          <div className="col-5 item text-right">
                            ${calculatePrice(item, serviceSlots)}
                          </div>
                          <div className="col-1 item text-right"></div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            fontWeight: "bold",
                          }}
                        >
                          <div className="col-6 item text-left">
                            Tax ({serviceSlots.service.tax} %)
                          </div>
                          <div className="col-5 item text-right">
                            +${calculateTaxes(item, serviceSlots)}
                          </div>
                          <div className="col-1 item text-right"></div>
                        </div>
                      </div>
                    );
                  });
                })}
            </div>

            <br />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div></div>
              <div
                className="input-group item"
                style={{ float: "right", width: 250 }}
              >
                <div className="form-group">
                  <label>Add promo code</label>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <input
                      type="text"
                      value={promoCode}
                      className="form-control"
                      placeholder="Promo code"
                      onChange={(event) => onChangePromoCode(event)}
                    />
                    <div className="input-group-append ml-2">
                      <span
                        style={{ cursor: "pointer", color: "#fff", height: 39 }}
                        type="submit"
                        onClick={checkPromoCode}
                        className="input-group-text bg-success"
                      >
                        Apply
                      </span>
                    </div>
                  </div>
                  {couponError.error && (
                    <p className="text-danger" style={{ fontSize: "15px" }}>
                      {couponError.msg}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <br />

            {membership && (
              <div className="cart">
                <div className="row align-item-baseline">
                  <div className="col-md-9 col-6 item text-md-right text-left">
                    <h5 className="m-0">
                      <b>
                        <span className="">Pay with your points</span>
                      </b>
                    </h5>
                  </div>
                  <div className="col-md-3 col-6 item text-right">
                    <h5 className="m-0">
                      <span className="">
                        <input
                          style={{ textAlign: "end" }}
                          type="number"
                          className="form-control"
                          value={usedPoints}
                          placeholder="Use your available points"
                          onChange={(event) => onChangePoints(event)}
                          disabled={
                            get(props.user, "total_points", null) > 0
                              ? false
                              : true
                          }
                        />
                        <small className="">
                          You have{" "}
                          <b className="text-success">
                            ({get(props.user, "total_points", null)})
                          </b>{" "}
                          points
                        </small>
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
            )}

            <div className="cart">
              <div className="row">
                <div className="col-md-9 col-6 item text-md-right text-left">
                  <h5 className="m-0">
                    <b>
                      Total price ($)<span className="vm_text_red"></span>
                    </b>
                  </h5>
                </div>
                <div className="col-md-3 col-6 item text-right">
                  <h5 className="m-0">
                    <b>${totalPrice.toFixed(2)}</b>
                  </h5>
                </div>
              </div>
            </div>

            {usedPoints > 0 && (
              <div className="cart">
                <div className="row">
                  <div className="col-md-9 col-6 item text-md-right text-left">
                    <h5 className="m-0">
                      <b>
                        Points<span className="vm_text_red"></span>
                      </b>
                    </h5>
                  </div>
                  <div className="col-md-3 col-6 item text-right">
                    <h5 className="m-0">
                      <b>-${usedPoints}</b>
                    </h5>
                  </div>
                </div>
              </div>
            )}

            <div className="cart">
              <div className="row">
                <div className="col-md-9 col-6 item text-md-right text-left">
                  <h5 className="m-0">
                    <b>
                      Total tax ($)<span className="vm_text_red"></span>
                    </b>
                  </h5>
                </div>
                <div className="col-md-3 col-6 item text-right">
                  <h5 className="m-0">
                    <b>${totalTax.toFixed(2)}</b>
                  </h5>
                </div>
              </div>
            </div>

            {holdingAmountData.holdAmount > 0 && (
              <div className="cart">
                <div className="row">
                  <div className="col-md-9 col-6 item text-md-right text-left">
                    <h5 className="m-0">
                      <b>Holding Amount</b>
                    </h5>
                  </div>
                  <div className="col-md-3 col-6 item text-right">
                    <h5 className="m-0">
                      <b>- ${holdingAmountData.holdAmount}</b>
                    </h5>
                  </div>
                </div>
              </div>
            )}

            <div className="cart">
              <div className="row">
                <div className="col-md-9 col-6 item text-md-right text-left">
                  <h5 className="m-0">
                    <b>
                      Grand Total (CAD)<span className="vm_text_red"></span>
                    </b>
                  </h5>
                </div>
                <div className="col-md-3 col-6 item text-right">
                  <h5 className="m-0">
                    <b>${calculateGrantTotal()}</b>
                    <span className="vm_text_red"></span>
                  </h5>
                </div>
              </div>
            </div>
          </div>

          {isAdmin ? (
            <div className="container">
              <form onSubmit={formik.handleSubmit}>
                <div>
                  <InputText
                    id={"customerName"}
                    label={"Customer Name"}
                    placeholder={"Please enter a name"}
                    value={formik.values.customerName}
                    error={
                      Boolean(formik.errors.customerName) &&
                      formik.touched.customerName
                    }
                    errorMessage={formik.errors.customerName}
                    getValue={(value) =>
                      formik.setFieldValue("customerName", value)
                    }
                    onBlur={() => formik.setFieldTouched("customerName")}
                  />
                </div>
                <div>
                  <InputSelect
                    selected={formik.values.shootType}
                    options={shootTypes}
                    label={"Shoot Type"}
                    placeholder={"Please select a shoot type."}
                    error={
                      Boolean(formik.errors.shootType) &&
                      formik.touched.shootType
                    }
                    errorMessage={formik.errors.shootType}
                    getValue={(value) =>
                      formik.setFieldValue("shootType", value)
                    }
                  />
                </div>

                <div>
                  <InputText
                    id={"notes"}
                    label={"Notes"}
                    placeholder={"Enter notes"}
                    value={formik.values.notes}
                    error={Boolean(formik.errors.notes) && formik.touched.notes}
                    errorMessage={formik.errors.notes}
                    getValue={(value) => formik.setFieldValue("notes", value)}
                    onBlur={() => formik.setFieldTouched("notes")}
                  />
                </div>

                <hr />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 10,
                  }}
                >
                  <button
                    type={"submit"}
                    className="btn btn-primary btn-bold px-4 mt-3 mt-lg-0"
                  >
                    Book Now
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <div
              style={{ display: "flex", justifyContent: "center", padding: 10 }}
            >
              <button
                onClick={() => nextProcess({ isAdmin: false })}
                className="btn btn-success"
              >
                Proceed next
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { user, membership } = state;
  return { user, membership };
};

export default connect(mapStateToProps, null)(BookedListTable);
