import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RootElement from "./component/RootElement";
import CustomLoader from "../../components/CustomLoader";
import { fetchHoldingAmountsByType } from "../../services/private/HoldingAmountService";

// Custom imports
import { APP_NAME } from "../../store/constance/actionTypes";

const Holdings = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [holdingAmountsState, setState] = useState([]);
  /*
   * Type should match ENUM in database
   * Check before passing any unrelated types
   */
  const [type, setType] = useState("PENDING");

  const fetchHoldingAmountData = async (type) => {
    setIsLoading(true);

    let response = await fetchHoldingAmountsByType(type);
    // state is not updating immediatly
    // So, I use await to see the changes but no use
    // I am only able to see the changes by another hook
    await setState(response.payload);
    await setType(type);
  };

  useEffect(() => {
    document.title = `Holding Amount - ${APP_NAME} Admin`;
    fetchHoldingAmountData("PENDING");
  }, []);

  // Capture the state changes
  useEffect(() => {
    // console.log("loading", holdingAmountsState);
    setIsLoading(false);
  }, [holdingAmountsState]);

  return (
    <RootElement>
      <div className="my-3">
        <button
          className="btn btn-sm btn-primary mr-2"
          onClick={() => fetchHoldingAmountData("PENDING")}
          disabled={type === "PENDING"}
        >
          Fetch Pending
        </button>
        <button
          className="btn btn-sm btn-success"
          onClick={() => fetchHoldingAmountData("SUCCESS")}
          disabled={type === "SUCCESS"}
        >
          Fetch Success
        </button>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Old Invoice ID</th>
            <th scope="col">New Invoice ID</th>
            <th scope="col">Grand Total</th>
            <th scope="col">Holding Amount</th>
            <th scope="col">Customer Exchange Value</th>
            <th scope="col">Change At</th>
          </tr>
        </thead>
        <tbody>
          {holdingAmountsState.length > 0 ? (
            holdingAmountsState.map((holding, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>
                  <Link
                    to={`/invoices/${holding.invoice_id}`}
                    className="font-weight-bold"
                  >
                    {holding.invoice_id}
                  </Link>
                </td>
                <td>
                  {holding.used_invoice_id ? (
                    <Link
                      to={`/invoices/${holding.used_invoice_id}`}
                      className="font-weight-bold"
                    >
                      {holding.used_invoice_id}
                    </Link>
                  ) : (
                    <p>No booking yet</p>
                  )}
                </td>
                <td>{holding.grant_total}</td>
                <td>{holding.holdAmount}</td>
                <td>{holding.member_ship_exchange_value}%</td>
                <td>{holding.created_at}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colspan="7" className="text-center">
                <h3 className="pt-5">
                  Currently, No data <i class="fas fa-exclamation pl-1"></i>
                </h3>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {isLoading && <CustomLoader isNoteOpen={true} />}
    </RootElement>
  );
};

export default Holdings;
