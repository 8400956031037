import React from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import { find, isEmpty, isEqual } from "lodash";
import "../../theme/css/invoiceTable.css";

import Skeleton from "@material-ui/lab/Skeleton";

// Custom imports
import {
  cancelBookingByAdmin,
  cancelSlots,
} from "../../services/private/InvoiceService";

function TableHead({ header }) {
  return (
    <thead>
      <tr>
        {header.map((header, index) => {
          return (
            <th
              style={{ textAlign: header.align, border: "none" }}
              scope="col"
              key={index}
            >
              {header.label}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

const PlainInvoiceTable = ({ header, bookingDetails, goToDetails, loading }) => {
  if (loading) {
    return (
      <div>
        <Skeleton
          variant="rect"
          width={300}
          height={35}
          style={{ marginBottom: 5 }}
        />
        <Skeleton variant="rect" height={50} style={{ marginBottom: 5 }} />
        <Skeleton variant="rect" height={150} style={{ marginBottom: 5 }} />
      </div>
    );
  }

  return (
    <table className="table">
      <TableHead header={header} />
      <tbody>
        {bookingDetails.map((bookingDetail, index) => {
          return (
            <tr key={index}>
              <td style={{ textAlign: "left" }}>{bookingDetail.id}</td>
              <td style={{ textAlign: "left" }}>
                {moment(bookingDetail.updated_at).format("YYYY-MM-DD")}
              </td>
              <td style={{ textAlign: "left" }}>
                {bookingDetail.photographer_name}
              </td>
              <td style={{ textAlign: "left" }}>{bookingDetail.shoot_type}</td>
              {/* <td style={{ textAlign: "left" }}>
                {bookingDetail.slots_details.length}
              </td>*/}
              <td style={{ textAlign: "left" }}>
                {!isEmpty(bookingDetail.cancel_type)
                  ? bookingDetail.status === "CANCELLING"
                    ? "Request to cancel"
                    : bookingDetail.status
                  : isEmpty(
                      find(bookingDetail.payments, {
                        payment_status: "SUCCESS",
                      })
                    )
                  ? "UNPAID"
                  : "PAID"}
              </td>
              <td
                className="td-actions"
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <button
                  style={{ marginTop: 0 }}
                  onClick={() => {
                    goToDetails && goToDetails(bookingDetail);
                  }}
                  type="button"
                  rel="tooltip"
                  className="btn btn-info btn-link btn-just-icon btn-sm"
                  data-original-title=""
                  title="Invoice"
                >
                  <i className="material-icons">description</i>
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const mapStateToProps = (state) => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, null)(PlainInvoiceTable);
