import API from "../../utils/api";

export const fetchData = async (type, data) => {
    try {
        let response = await API.get("/dashboard/" + type, { params: data });
        return response.data;
    } catch (e) {
        throw e;
    }
};
