import { combineReducers, createStore, applyMiddleware } from "redux";
import {
  authReducer,
  appReducer,
  couponReducer,
  userReducer,
  bookingReducer,
  membershipReducer,
  bookingDetailsReducer,
  customerReducer,
} from "./reducers";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import { AsyncStorage } from "AsyncStorage";

const persistConfig = {
  key: "root",
  storage: AsyncStorage,
  version: 0,
};

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  user: userReducer,
  slots: bookingReducer,
  membership: membershipReducer,
  bookingDetails: bookingDetailsReducer,
  coupon: couponReducer,
  customer: customerReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(thunk));

export const persistor = persistStore(store);
export default store;
