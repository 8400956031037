import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import InputText from "../core/InputText/InputText";
import InputSelect from "../core/InputSelect/InputSelect";
import MultipleSelect from "../core/MultipleSelect/MultipleSelect";

const useStyles = makeStyles((theme) => ({
    footer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        "&>:first-child": {
            marginRight: "12px",
        },
    },
}));

const UserForm = (props) => {
    const { initialValues, branches, errors } = props;
    const classes = useStyles();

    console.log(`branches`,branches);

    const formik = useFormik({
        initialValues: {
            firstname: initialValues.firstname ? initialValues.firstname : "",
            lastname: initialValues.lastname ? initialValues.lastname : "",
            email: initialValues.email ? initialValues.email : "",
            role: initialValues.role ? initialValues.role : "",
            branch_ids: initialValues.branch_ids
                ? initialValues.branch_ids
                : [],
        },
        validationSchema: Yup.object({
            firstname: Yup.string().required("Required"),
            lastname: Yup.string().required("Required"),
            email: Yup.string().required("Required"),
            // role: Yup.string().required('Required'),
            // branch_ids: Yup.string().required('Required'),
        }),
        onSubmit: (values) => {
            // console.log(`values => `, values);

            const { getFormValue } = props;

            if (getFormValue) {
                getFormValue(values);
            }
        },
    });

    return (
        <div style={{ padding: "24px", paddingTop: "0" }}>
            <form onSubmit={formik.handleSubmit} style={{ marginTop: "0px" }}>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <InputText
                            id={"firstname"}
                            label={"First Name"}
                            placeholder={"First Name"}
                            value={formik.values.firstname}
                            error={
                                Boolean(formik.errors.firstname) &&
                                formik.touched.firstname
                            }
                            errorMessage={formik.errors.firstname}
                            getValue={(value) =>
                                formik.setFieldValue("firstname", value)
                            }
                            onBlur={() => formik.setFieldTouched("firstname")}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <InputText
                            id={"lastname"}
                            label={"Last Name"}
                            placeholder={"Last Name"}
                            value={formik.values.lastname}
                            error={
                                Boolean(formik.errors.lastname) &&
                                formik.touched.lastname
                            }
                            errorMessage={formik.errors.lastname}
                            getValue={(value) =>
                                formik.setFieldValue("lastname", value)
                            }
                            onBlur={() => formik.setFieldTouched("lastname")}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <InputText
                            id={"email"}
                            label={"Email"}
                            placeholder={"Email"}
                            value={formik.values.email}
                            error={
                                Boolean(formik.errors.email) &&
                                formik.touched.email
                            }
                            errorMessage={formik.errors.email}
                            getValue={(value) =>
                                formik.setFieldValue("email", value)
                            }
                            onBlur={() => formik.setFieldTouched("email")}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <InputSelect
                            options={roleOption}
                            placeholder={"Select the role"}
                            label={"User role"}
                            selected={formik.values.role}
                            isLabel={true}
                            getValue={(value) =>
                                formik.setFieldValue("role", value)
                            }
                        />
                    </div>
                    {formik.values.role === "manager" && (
                        <div className="form-group col-md-6">
                            <MultipleSelect
                                setValue={(value) =>
                                    formik.setFieldValue("branch_ids", value)
                                }
                                selected={formik.values.branch_ids}
                                label={"Please select the branches"}
                                options={branches}
                            />
                        </div>
                    )}
                </div>
                <p style={{ color: "#225e0e" }}>
                    We will send you the password
                </p>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        color: "red",
                        padding: "16px",
                    }}
                >
                    {Object.keys(errors).map((key) => {
                        return (
                            <div>
                                {errors[key].map((error) => {
                                    return <p>{error}</p>;
                                })}
                            </div>
                        );
                    })}
                </div>
                <div className={classes.footer}>
                    <button
                        onClick={props.handleClose && props.handleClose}
                        className="btn btn-danger"
                    >
                        Cancel
                    </button>
                    <button type={"submit"} className="btn btn-success">
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};
export default UserForm;
const roleOption = [
    { label: "Manager", value: "manager" },
    { label: "User", value: "user" },
];
