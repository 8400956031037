import * as React from "react";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "#fff",
  border: "2px solid #000",
};

const boxStyle = {
  background: "#fff",
  width: "90%",
  margin: "10% auto",
  padding: "20px",
};

export default function CustomModal(props) {
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <Box sx={style} className="waseem" style={boxStyle}>
          {props.children}
        </Box>
      </Modal>
    </div>
  );
}
