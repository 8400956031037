import React, { Component } from "react";
import RootElement from "./component/RootElement";
import {
  cancelBookingByAdmin,
  requestToCancel,
  confirmCancel,
  fetchBookingDetails,
} from "../../services/private/InvoiceService";
import { connect } from "react-redux";
import InvoiceTable from "../../components/tables/InvoiceTable";
import CustomLoader from "../../components/CustomLoader";
import PaginationComponent from "../../components/PaginationComponent";
import AlertBar from "../../components/core/Alert/AlertBar";
import Swal from "sweetalert2";
import { find } from "lodash";
import DateNavigator from "../../components/core/dateNavigator/DateNavigator";
import moment from "moment";

// Custom imports
import { APP_NAME } from "../../store/constance/actionTypes";

class BookingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingDetails: [],
      paginationDetail: {
        current_page: 1,
        first_page_url: "",
        from: 1,
        next_page_url: "",
        path: "",
        per_page: 5,
        prev_page_url: null,
        to: 5,
      },
      isLoading: false,
      tableHeader: [
        {
          align: "left",
          key: "id",
          label: "Reference ID",
        },
        {
          align: "left",
          key: "date",
          label: "Booked date",
        },
        {
          align: "left",
          key: "photographer_name",
          label: "Photographer name",
        },
        {
          align: "left",
          key: "shoot_type",
          label: "Shoot type",
        },
        {
          align: "left",
          key: "No of slots",
          label: "No of slots",
        },
        {
          align: "left",
          key: "status",
          label: "Status",
        },
        {
          align: "center",
          key: "action",
          label: "Action",
        },
      ],
      alertOpen: false,
      alertMessage: "",
      errors: {},
      alertType: "",
      date: moment().format("YYYY-MM-DD"),
    };
  }

  componentDidMount = async () => {
    document.title = `Bookings - ${APP_NAME} Admin`;
    this._fetchBookingDetails(this.state.paginationDetail.current_page);
  };

  _fetchBookingDetails = async (page) => {
    try {
      this.setState({ isLoading: true });

      const reqDate = { page: page };

      const bookingDetailsResponse = await fetchBookingDetails(reqDate);

      this.setState({
        bookingDetails: bookingDetailsResponse.data.data,
        paginationDetail: {
          ...bookingDetailsResponse.data,
          data: [],
        },
        isLoading: false,
      });

      return Promise.resolve(200);
    } catch (e) {
      console.log(`error`, e);
      this.setState({
        isLoading: false,
      });

      return Promise.reject(400);
    }
  };

  handleChangeDate = async (value) => {
    this._fetchBookingDetails(this.state.paginationDetail.current_page);
  };

  filterByBookingStatus = (status) => {};

  reRenderApi = async () => {
    const { user } = this.props;
    const { date } = this.state;

    try {
      // const reqDate = {
      //   start_date: moment(date).format("YYYY-MM-DD"),
      //   end_date: moment(date).add(1, "weeks").format("YYYY-MM-DD"),
      // };

      // const bookingDetailsResponse = await fetchBookingDetails(reqDate);

      // this.setState({ bookingDetails: bookingDetailsResponse.data.data });
      this._fetchBookingDetails(this.state.paginationDetail.current_page);
    } catch (e) {}
  };

  requestToCancel = async (data, type) => {
    try {
      const response = await requestToCancel(data.id, {
        cancel_type: type,
      });
      this.reRenderApi().then((r) =>
        this.setState({
          isLoading: false,
          alertOpen: true,
          alertMessage: response.message,
          alertType: "success",
        })
      );
    } catch (e) {
      if (e.response && e.response.data && e.response.data.errors) {
        this.setState({
          isLoading: false,
          errors: e.response.data.errors,
          alertOpen: true,
          alertMessage: e.response.data.errors.message
            ? e.response.data.errors.message
            : "Something went wrong",
          alertType: "error",
        });
      } else {
        this.setState({
          isLoading: false,
          alertOpen: true,
          alertMessage: "Something went wrong",
          alertType: "error",
        });
      }
    }
  };

  confirmCancel = async (data) => {
    const { user } = this.props;
    const { date } = this.state;

    const inputOptions = new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          MOVE_TO_POINTS: "Move to points",
          REFUND: "Refund",
        });
      }, 100);
    });

    const { value: type } = await Swal.fire({
      title: `Are you sure? You want to cancel`,
      text: "Please select the cancel option",
      input: "radio",
      inputOptions: inputOptions,
      inputValidator: (value) => {
        if (!value) {
          return "You need to choose something!";
        }
      },
      showCloseButton: true,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: `Cancel`,
      confirmButtonText: `Confirm`,
      confirmButtonColor: "#5cb85c",
    });

    if (!type) {
      return;
    }

    const swal = await Swal.fire({
      title: "Are you sure?",
      text: "You want to confirm the cancellation. You cannot revert this process!",
      icon: "success",
      showCloseButton: true,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: `Cancel`,
      confirmButtonText: `Confirm`,
      confirmButtonColor: "#5cb85c",
    });

    console.log(`final`);
    // return;

    if (swal.value) {
      try {
        const reqData = {
          sub_total: find(data.payments, { payment_status: "SUCCESS" })
            .sub_total,
          discount: find(data.payments, { payment_status: "SUCCESS" }).discount,
          grant_total: find(data.payments, { payment_status: "SUCCESS" })
            .grant_total,
          booked_by: `cancel by ${user.firstname}`,
          cancel_type: type,
        };

        const response = await confirmCancel(data.id, reqData);
        await this._fetchBookingDetails(
          this.state.paginationDetail.current_page
        );

        this.setState({
          alertOpen: true,
          alertMessage: response.message,
          alertType: "success",
        });
      } catch (e) {
        this.setState({
          isLoading: false,
          alertOpen: true,
          alertMessage: "Something went wrong!",
          alertType: "error",
        });
      }
    }
  };

  setLoadingState = async (value) => {
    this.setState({ isLoading: value });
  };

  render() {
    const {
      bookingDetails,
      tableHeader,
      isLoading,
      alertOpen,
      alertMessage,
      alertType,
      date,
      paginationDetail,
    } = this.state;

    return (
      <RootElement>
        <div>
          {/*<DateNavigator
            disablePast={false}
            disableFuture={false}
            type={"WEEK"}
            isShowDate={true}
            date={date}
            onChangeDate={(date) => this.handleChangeDate(date)}
          />*/}
          {bookingDetails && (
            <InvoiceTable
              goToDetails={(data) =>
                this.props.history.push("/invoices/" + data.id)
              }
              confirmCancel={(data) => this.confirmCancel(data)}
              header={tableHeader}
              bookingDetails={bookingDetails}
              requestToCancel={(data, type) => this.requestToCancel(data, type)}
              reRenderApi={() => this.reRenderApi()}
              setLoadingState={(value) => this.setLoadingState(value)}
            >
              {paginationDetail && paginationDetail.links && (
                <PaginationComponent
                  data={paginationDetail}
                  setCurrentPage={(page) => this._fetchBookingDetails(page)}
                />
              )}
            </InvoiceTable>
          )}
        </div>

        {isLoading && <CustomLoader isNoteOpen={true} />}
        {alertOpen && (
          <AlertBar
            alertMessage={alertMessage}
            alertType={alertType}
            onClose={() => this.setState({ alertOpen: false })}
          />
        )}
      </RootElement>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;
  return { user };
};
export default connect(mapStateToProps, null)(BookingDetails);
