import API from "../../utils/api";

export const fetchPageDetails = async (pageName, data) => {
    console.log(`pageName`, pageName);
    try {
        let response = await API.get("/page/" + pageName, data);
        return response.data;
    } catch (e) {
        throw e;
    }
};

export const updateAboutText = async (data) => {
    try {
        let response = await API.put("/page/" + data.id, data);
        return response.data;
    } catch (e) {
        throw e;
    }
};

export const uploadImage = async (data) => {
    try {
        let response = await API.post("/page/image/upload", data);
        return response.data;
    } catch (e) {
        throw e;
    }
};

export const deleteImage = async (data) => {
    try {
        let response = await API.post("/page/image/delete", data);
        return response.data;
    } catch (e) {
        throw e;
    }
};
