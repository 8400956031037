import API from "../../utils/api";

export const fetchUsedCoupons = async (count) => {
    let coupon = await API.get("coupon/used/all/" + count);

    return coupon.data;
};

export const fetchUsedCouponsById = async (id, reqData) => {
    let coupon = await API.get("coupon/used/" + id, {
        params: reqData,
    });

    return coupon.data;
};

export const getCoupons = async () => {
    let coupon = await API.get("coupon/all");

    return coupon.data;
};

export const addCoupon = async (data) => {
    try {
        let coupon = await API.post("coupon", data);
        return coupon.data;
    } catch (e) {
        throw e;
    }
};

export const updateCoupon = async (id, data) => {
    try {
        let coupon = await API.post("coupon/" + id, data);
        return coupon.data;
    } catch (e) {
        throw e;
    }
};

export const deleteCoupon = async (id) => {
    try {
        let coupon = await API.post("coupon/delete/" + id);
        return coupon.data;
    } catch (e) {
        throw e;
    }
};
