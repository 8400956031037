import React from "react";
import img from "../../assets/img/logo2.png";
import LoginForm from "../forms/LoginForm";
import { useHistory } from "react-router";

// components
import PasswordResetEmailForm from "../forms/PasswordResetEmailForm";
import PasswordResetConfirmCodeForm from "../forms/PasswordResetConfirmCodeForm";
import ConfirmPasswordForm from "../forms/ConfirmPasswordForm";

const LoginComponent = ({
    getFormValue,
    errors,
    sendPasswordResetEmail,
    passwordResetEmailSent,
    passwordResetCodeConfirmed,
    validatePasswordResetCode,
    confirmPasswordReset,
    ...props
}) => {
    //
    const history = useHistory();

    const [showPasswordReset, setShowPasswordReset] = React.useState(false);

    const sendResetEmail = (data) => {
        sendPasswordResetEmail(data);
    };

    return (
        <div className="container pt-5">
            <div className="row">
                <div className="col-md-3"></div>

                <div className="col-md-6">
                    <div className="col-md-12 d-flex justify-content-center">
                        <img src={img} alt="" className="col-md-6" />
                    </div>

                    {!showPasswordReset ? (
                        <>
                            <div className="mt-5 card_wrapper">
                                <h3 className="pb-3 d-flex justify-content-center">
                                    Login
                                </h3>

                                <LoginForm
                                    getFormValue={getFormValue}
                                    errors={errors}
                                    showPasswordReset={() =>
                                        setShowPasswordReset(true)
                                    }
                                />
                            </div>

                            <div className="d-flex justify-content-center">
                                <p className="p-4">
                                    <a
                                        href={true}
                                        style={{
                                            cursor: "pointer",
                                            color: "#007bff",
                                        }}
                                        onClick={() => history.push("register")}
                                    >
                                        Create New Account
                                    </a>
                                </p>
                            </div>
                        </>
                    ) : (
                        <div className="mt-5 card_wrapper">
                            <h3 className="pb-3 d-flex justify-content-center">
                                Reset Password
                            </h3>

                            {showPasswordReset &&
                                !passwordResetEmailSent &&
                                !passwordResetCodeConfirmed && (
                                    <PasswordResetEmailForm
                                        errors={errors}
                                        getFormValue={sendResetEmail}
                                    />
                                )}

                            {passwordResetEmailSent &&
                                !passwordResetCodeConfirmed && (
                                    <PasswordResetConfirmCodeForm
                                        errors={errors}
                                        getFormValue={validatePasswordResetCode}
                                    />
                                )}

                            {passwordResetCodeConfirmed && (
                                <ConfirmPasswordForm
                                    errors={errors}
                                    getFormValue={confirmPasswordReset}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default LoginComponent;
