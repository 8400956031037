import API from "../utils/api";
import store from "../store";
import { setToken } from "../store/actions/authAction";
import { showModal, saveInvoices } from "../store/actions/appActions";
import { fetchUser } from "../store/actions/userAction";
import {
  removeMembership,
  setMembership,
} from "../store/actions/memberShipAction";
import { clearSlots } from "../store/actions/bookingAction";
import { removeBookingDetails } from "../store/actions/bookingDetailsAction";
import { saveCustomer, saveCustomers } from "../store/actions/customerActions";

export const loginUser = async (data) => {
  try {
    const response = await API.post(`auth/login`, data);
    await store.dispatch(fetchUser(response.data.data.user));
    await store.dispatch(setMembership(response.data.data.user.member_ship));
    await store.dispatch(setToken(response.data.data.access_token));
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const refreshToken = async (data) => {
  try {
    const response = await API.post(`auth/refresh`, data);
    await store.dispatch(setToken(response.data.data.access_token));
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const sendResetEmail = async (data) => {
  try {
    const response = await API.get(`auth/password/reset`, { params: data });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const confirmResetEmailCode = async (data) => {
  try {
    const response = await API.post(`auth/password/confirm-code`, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const resetPassword = async (data) => {
  try {
    const response = await API.post(`auth/password/reset`, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const logOut = async () => {
  const initialModalData = {
    bookingdetails: {
      id: "",
      photographer_name: "",
      shoot_type: "",
      payments: [
        {
          sub_total: 0,
        },
      ],
    },
    service: {
      name: "",
    },
    shift: {
      day: "",
      name: "",
      start_time: "",
      end_time: "",
      actual_price: 0,
      price: 0,
    },
  };

  try {
    // await API.post( `/auth/logout`);
    await store.dispatch(setToken(false));
    await store.dispatch(fetchUser(false));
    await store.dispatch(removeMembership(false));
    await store.dispatch(clearSlots([]));
    await store.dispatch(saveInvoices([]));
    await store.dispatch(removeBookingDetails(null));
    await store.dispatch(saveCustomer({ customer: {} }));
    await store.dispatch(saveCustomers({ customers: {} }));
    await store.dispatch(
      showModal({ showModal: false, modalData: initialModalData })
    );

    localStorage.clear();

    return true;
  } catch (err) {
    throw err;
  }
};
