import API from "../../utils/api";

export const allCustomers = async (data) => {
	try {
		let customers = await API.get("/customers");
		return customers.data;
	} catch (e) {
		throw e;
	}
};

export const updateCustomer = async (data) => {
	try {
		let customers = await API.put("/customers" + data.id, data);
		return customers.data;
	} catch (e) {
		throw e;
	}
};
