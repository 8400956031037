import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import BootstrapInputText from "../core/InputText/BootstrapInputText";

const useStyles = makeStyles((theme) => ({
    footer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        "&>:first-child": {
            marginRight: "12px",
        },
    },
}));
const PasswordResetEmailForm = (props) => {
    const { errors } = props;
    const classes = useStyles();

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Please enter valid email address")
                .required("Required"),
        }),
        onSubmit: (values) => {
            const { getFormValue } = props;
            if (getFormValue) {
                getFormValue(values);
            }
        },
    });

    return (
        <div
            style={{
                padding: "30px",
                paddingTop: "0",
                paddingLeft: "15px",
                marginTop: 30,
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="form-row">
                    <BootstrapInputText
                        id={"email"}
                        label={"Enter your email"}
                        placeholder={"Enter your email"}
                        value={formik.values.email}
                        error={
                            Boolean(formik.errors.email) && formik.touched.email
                        }
                        errorMessage={formik.errors.email}
                        getValue={(value) =>
                            formik.setFieldValue("email", value)
                        }
                        onBlur={() => formik.setFieldTouched("email")}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        color: "red",
                        padding: "16px",
                    }}
                >
                    {errors.map((error, index) => {
                        return <p key={index}>{error}</p>;
                    })}
                </div>
                <div className={classes.footer}>
                    <button type={"submit"} className="btn btn-success">
                        Send email
                    </button>
                </div>
            </form>
        </div>
    );
};
export default PasswordResetEmailForm;
