import React, { Component } from "react";
import PublicRootElement from "./admin/component/PublicRootElement";
import { connect } from "react-redux";
import { fetchBookingDetailsById } from "../services/private/InvoiceService";
import InvoiceComponent from "../components/Invoice";
import { isEmpty, cloneDeep, chain } from "lodash";
import { getAllServices } from "../services/private/ServicesService";
import CustomLoader from "../components/CustomLoader";
import {
  checkAvailability,
  saveBookingData,
} from "../services/private/BookingService";

// state
import store from "../store";
import { saveCustomer } from "../store/actions/customerActions";

// Custom imports
import { APP_NAME } from "../store/constance/actionTypes";

class Invoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceDetails: [],
      isLoading: false,
      tableHeader: [
        {
          align: "left",
          key: "photographer_name",
          label: "Photographer name",
        },
        {
          align: "left",
          key: "shoot_type",
          label: "Shoot type",
        },
        {
          align: "left",
          key: "No of slots",
          label: "No of slots",
        },
      ],
      services: [],
      normalUser: true,
      isAdmin: false,
    };
  }

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    const { user } = this.props;

    // console.log(`normalUser before => `, this.state.normalUser);
    if (user) {
      // console.log(`user => `, user);
      let found = user.roles.includes("super_admin");
      // console.log(`found => `, found);

      found && this.setState({ isAdmin: true });

      // this.setState({ normalUser: "aaaa" });
    }

    this.setState({ normalUser: "padu" });
    // console.log(`normalUser => `, this.state.normalUser);

    const {
      match: { params },
    } = this.props;

    let requestData = {};

    try {
      if (user.roles.includes("user")) {
        requestData = {
          user_id: user.id,
        };
      }

      const details = await fetchBookingDetailsById(
        params.invoiceId,
        requestData
      );

      const servicesResponse = await getAllServices();
      const copyData = cloneDeep(details.data);

      const mappingWithService = copyData.slots_details.map((slot) => ({
        ...slot,
        service: servicesResponse.data.filter(
          (item) => item.id === slot.service_id
        )[0],
      }));

      details.data.slots_details = mappingWithService;

      // console.log(`details => `, details.data);
      this.setState({ invoiceDetails: details.data, isLoading: false });

      // set document title
      document.title = `Invoice >> ${details.data.id} - ${APP_NAME}`;
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  formatForCheckAvailability = (slots) => {
    const groupData = chain(slots)
      .groupBy("service_id")
      .map((value, key) => ({
        service_id: key,
        data: value,
      }))
      .value();
    console.log(groupData);
    return groupData.map((slot) => ({
      service_id: slot.service_id,
      slots: slot.data.map((item) => {
        return item.slot;
      }),
    }));
  };

  payAgain = async (data) => {
    const groupData = chain(this.state.invoiceDetails.slots_details)
      .groupBy("service_id")
      .map((value, key) => ({
        service_id: parseInt(key),
        slots: value.map((item) => ({
          price: item.price,
          shift_id: item.shift_id,
          slot: item.slot,
        })),
      }))
      .value();

    const dataFormat = {
      photographer_name: data.photographer_name,
      shoot_type: data.shoot_type,
      booking_detail_id: data.id,
      payment: {
        sub_total: data.payments[data.payments.length - 1].sub_total,
        discount: data.payments[data.payments.length - 1].discount,
        coupon_discount:
          data.payments[data.payments.length - 1].coupon_discount,
        grant_total: data.payments[data.payments.length - 1].grant_total,
        promo_code_ids:
          data.payments[data.payments.length - 1].promo_code_id || null,
        used_points: data.payments[data.payments.length - 1].used_points,
        tax_amount: data.payments[data.payments.length - 1].tax_amount,
        booked_by: data.payments[data.payments.length - 1].booked_by,
        payment_status: "SUCCESS",
        payment_type: data.payment_type,
        gateway_response: "{}",
      },
      booking_data: groupData,
      notes: data.notes,
      pay_with: data.pay_with,
    };

    // override the booked by value if admin booking
    if (this.state.isAdmin) {
      dataFormat.payment.booked_by = "Admin";
    }

    try {
      await checkAvailability(
        this.formatForCheckAvailability(data.slots_details)
      );

      await saveBookingData(data.user_id, dataFormat);

      return Promise.resolve(201);
    } catch (e) {
      console.log(`err`, e.response.data.data);
      return Promise.reject(e);
    }
  };

  goToDetails = async (userData) => {
    await store.dispatch(saveCustomer({ customer: userData }));
    this.props.history.push("/profile");
  };

  render() {
    const {
      match: { params },
    } = this.props;
    const { invoiceDetails, isLoading, isAdmin } = this.state;
    return (
      <PublicRootElement>
        {!isEmpty(invoiceDetails) && (
          <InvoiceComponent
            payAgain={(data) => this.payAgain(data)}
            invoiceId={params.invoiceId}
            data={invoiceDetails}
            isAdmin={isAdmin}
            goToDetails={this.goToDetails}
          />
        )}
        {isLoading && <CustomLoader isNoteOpen={true} />}
      </PublicRootElement>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;
  return { user };
};
export default connect(mapStateToProps, null)(Invoice);
