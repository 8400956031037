import React, { useState, useEffect } from "react";
import CropFreeIcon from "@material-ui/icons/CropFree";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

const CarouselComponent = (props) => {
  const [max, setMax] = useState(false);
  const [count, setCount] = useState(0);
  const [images, setImages] = useState([]);

  /*const images = [9, 8, 7, 6, 5].map((number) => ({
    src: `https://placedog.net/${number}00/${number}00?id=${number}`,
  }));*/

  const makeMax = () => {
    // console.log(`make => `);
    setMax(true);

    setTimeout(() => {
      setCount(count + 1);
    }, 500);
  };



  useEffect(() => {
    if (props.images) {
      const filtered = props.images.map((image) => {
        return {
          src: `${process.env.REACT_APP_API}/storage/${image.path}`,
          // src: `https://placedog.net/500/500?id=5`,
        };
      });

      setImages(filtered);
    }
  }, []);

  return (
    <Carousel
      style={{ height: 500, width: '74%' }}
      images={images}
      isMaximized={max}
      isAutoPlaying={true}
      onTap={makeMax}
    />
  );
};

export default CarouselComponent;
