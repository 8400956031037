import API from "../../utils/api";

export const allCards = async (id) => {
    try {
        let res = await API.get("/user/cards/" + id);
        return res.data;
    } catch (e) {
        throw e;
    }
};

export const addNewCard = async (userId, data) => {
    try {
        let res = await API.post("/user/cards/" + userId, data);
        return res.data;
    } catch (e) {
        throw e;
    }
};

export const changeCardActiveStatus = async (id, data) => {
    try {
        let res = await API.get("/user/cards/" + id + "/active", {
            params: data,
        });
        return res.data;
    } catch (e) {
        throw e;
    }
};
