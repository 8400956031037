import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import BootstrapInputText from "../core/InputText/BootstrapInputText";

const useStyles = makeStyles((theme) => ({
    footer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        "&>:first-child": {
            marginRight: "12px",
        },
    },
}));
const ConfirmPasswordForm = (props) => {
    const { errors } = props;
    const classes = useStyles();

    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .min(6, "Password should be at least 6 characters.")
                .required("Required"),
            confirmPassword: Yup.string().when("password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Password doesn't match"
                ),
            }),
        }),
        onSubmit: (values) => {
            const { getFormValue } = props;
            if (getFormValue) {
                getFormValue(values);
            }
        },
    });

    return (
        <div
            style={{
                padding: "30px",
                paddingTop: "0",
                paddingLeft: "15px",
                marginTop: 30,
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="form-row">
                    <BootstrapInputText
                        id={"password"}
                        label={"Password"}
                        placeholder={"Enter your new password"}
                        type={"password"}
                        value={formik.values.password}
                        error={
                            Boolean(formik.errors.password) &&
                            formik.touched.password
                        }
                        errorMessage={formik.errors.password}
                        getValue={(value) =>
                            formik.setFieldValue("password", value)
                        }
                        onBlur={() => formik.setFieldTouched("password")}
                    />
                </div>
                <div className="form-row">
                    <BootstrapInputText
                        id={"confirm-password"}
                        label={"Confirm Password"}
                        placeholder={"Enter confirm password"}
                        type={"password"}
                        value={formik.values.confirmPassword}
                        error={
                            Boolean(formik.errors.confirmPassword) &&
                            formik.touched.confirmPassword
                        }
                        errorMessage={formik.errors.confirmPassword}
                        getValue={(value) =>
                            formik.setFieldValue("confirmPassword", value)
                        }
                        onBlur={() => formik.setFieldTouched("confirmPassword")}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        color: "red",
                        padding: "16px",
                    }}
                >
                   {errors.map((error, index) => {
                        return <p key={index}>{error}</p>;
                    })}
                </div>
                <div className={classes.footer}>
                    <button type={"submit"} className="btn btn-success">
                        Reset Password
                    </button>
                </div>
            </form>
        </div>
    );
};
export default ConfirmPasswordForm;
