import API from "../utils/api";

export const registerUser = async (data) => {
  let response = await API.post(`/user/public`, data);

  return response.data;
};

export const resendActivationCode = async (data) => {
  let response = await API.post(`/user/resend`, data);
  return response.data;
};

export const activeAccount = async (data) => {
  let response = await API.post(`/user/active`, data);
  return response.data;
};

export const fetchUserDetails = async () => {
  console.log(`Fetching user details`);
  let response = await API.get(`/user`);
  console.log(`response.data.data`,response.data.data);
  return response.data.data;
};

export const fetchPoints = async (id, data) => {
  let response = await API.get(`/user/points/${id}`, { params: data });
  return response.data;
};
