import React, { Component } from "react";
import { Link } from "react-router-dom";
import RootElement from "./component/RootElement";
import CustomCalendar from "../../components/CustomCalendar";
import { fetchBookedDataPrivate } from "../../services/private/BookingService";
import moment from "moment";
// import Moment from "react-moment";
import { chain, cloneDeep, isEmpty } from "lodash";
import { withStyles } from "@material-ui/core";
import InputSelect from "../../components/core/InputSelect/InputSelect";
import { getAllServices } from "../../services/private/ServicesService";
import SearchInput from "../../components/core/style/SearchInput";
import CustomLoader from "../../components/CustomLoader";
import { connect } from "react-redux";
import store from "../../store";
import { showModal } from "../../store/actions/appActions";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PersonIcon from "@material-ui/icons/Person";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

// Custom imports
import { APP_NAME } from "../../store/constance/actionTypes";
import Modal from "../../components/modal/Modal";

const styles = (theme) => ({
  content: {
    minWidth: "1600px",
    overflow: "auto",
    padding: theme.spacing(3),
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "12px",
  },
});

class Booking extends Component {
  constructor(props) {
    super(props);
    this.originalBookedData = [];

    this.state = {
      bookedSlots: [],
      serviceId: 1,
      date: moment(),
      services: [],
      isLoading: false,
    };
  }

  componentDidMount = async () => {
    document.title = `Bookings - ${APP_NAME} Admin`;

    this.setState({ isLoading: true });
    try {
      const data = {
        // service_id: this.state.serviceId,
        start_date: moment()
          .subtract(1, "months")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().add(1, "months").format("YYYY-MM-DD HH:mm:ss"),
      };
      const services = await getAllServices();
      const response = await fetchBookedDataPrivate(data);
      const groupBy = chain(response.data)
        .groupBy("service_id")
        .map((value, key) => ({
          service_id: key,
          data: value,
        }))
        .value();
      let merged = [];
      groupBy.forEach((item) => {
        item.data.forEach((data) => {
          merged = [...merged, data];
        });
      });

      const bookedData = merged.map((data) => ({
        ...data,
        title: data.status,
        start: data.slot,
      }));
      this.originalBookedData = bookedData;
      this.setState({
        bookedSlots: bookedData,
        services: services.data.map((service) => ({
          label: service.name,
          value: service.id,
          isLoading: false,
        })),
      });
    } catch (e) {}
  };

  selectByService = (value) => {
    let cloneOriginalBookedData = cloneDeep(this.originalBookedData);
    if (value !== "default") {
      this.setState({
        bookedSlots: this.originalBookedData.filter(
          (item) => item.service_id === value
        ),
      });
    } else {
      this.setState({ bookedSlots: cloneOriginalBookedData });
    }
  };

  dateChange = async (start, end) => {
    if (
      moment(start, "YYYY-MM-DD HH:mm:ss").isSame(
        this.state.date,
        "YYYY-MM-DD HH:mm:ss"
      )
    ) {
      return;
    }
    // this.setState({isLoading:true});
    try {
      const data = {
        // service_id: this.state.serviceId,
        start_date: moment(start, "YYYY-MM-DD").format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment(end, "YYYY-MM-DD").format("YYYY-MM-DD HH:mm:ss"),
      };

      const response = await fetchBookedDataPrivate(data);
      const groupBy = chain(response.data)
        .groupBy("service_id")
        .map((value, key) => ({
          service_id: key,
          data: value,
        }))
        .value();
      let merged = [];
      groupBy.forEach((item) => {
        item.data.forEach((data) => {
          merged = [...merged, data];
        });
      });
      const bookedData = merged.map((data) => ({
        ...data,
        title: data.status,
        start: data.slot,
      }));
      this.originalBookedData = bookedData;
      this.setState({
        bookedSlots: bookedData,
        date: start,
        isLoading: false,
      });
    } catch (e) {}
  };

  handleClose = () => {
    // console.log(`closing modal => `);
    const initialModalData = {
      bookingdetails: {
        id: "",
        photographer_name: "",
        shoot_type: "",
        payments: [
          {
            sub_total: 0,
          },
        ],
      },
      service: {
        name: "",
      },
      shift: {
        day: "",
        name: "",
        start_time: "",
        end_time: "",
        actual_price: 0,
        price: 0,
      },
    };

    store.dispatch(
      showModal({ showModal: false, modalData: initialModalData })
    );
  };

  goToDetails = (id) => {
    this.props.history.push("invoices/" + id);
  };

  render() {
    const { classes, modalData } = this.props;
    const { bookedSlots, services, isLoading } = this.state;

    console.log(`modalData => `, modalData);

    return (
      <RootElement>
        <div className={classes.header}>
          <div style={{ marginTop: "20px", width: "200px" }}>
            <SearchInput />
          </div>

          <div>
            <Modal open={this.props.showModal} handleClose={this.handleClose}>
              <div className="container overflow-auto">
                <div className="header">
                  <h3>
                    Booking Details <DoubleArrowIcon />{" "}
                    {modalData.bookingdetails.id}
                  </h3>
                  <hr />
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="card p-3">
                      <h5 className="text-success">
                        <CheckCircleIcon className="mr-2" />
                        Booking Confirmed
                      </h5>
                    </div>

                    <div className="card p-3 my-3">
                      <h5>Other Details</h5>
                      <hr />

                      <div className="d-flex justify-content-between align-item-center">
                        <p>
                          <PersonIcon className="mr-2" />
                          Name
                        </p>
                        <p>{modalData.bookingdetails.photographer_name}</p>
                      </div>
                      <div className="d-flex justify-content-between align-item-center">
                        <p>
                          <PhotoCameraIcon className="mr-2" />
                          Shoot Type
                        </p>
                        <p>{modalData.bookingdetails.shoot_type}</p>
                      </div>
                    </div>

                    <div className="card p-3 my-3">
                      <h5 className="mb-2">{modalData.service.name}</h5>
                      <hr />

                      <div className="d-flex justify-content-between align-item-center">
                        <p>Shift Name</p>
                        <p>{modalData.shift.name}</p>
                      </div>
                      <div className="d-flex justify-content-between align-item-center">
                        <p>Booked Day</p>
                        <p>{modalData.shift.day}</p>
                      </div>
                      <div className="d-flex justify-content-between align-item-center">
                        <p>Start Time</p>
                        <p>{modalData.shift.start_time}</p>
                      </div>
                      <div className="d-flex justify-content-between align-item-center">
                        <p>End Time</p>
                        <p>{modalData.shift.end_time}</p>
                      </div>
                      <div className="d-flex justify-content-between align-item-center">
                        <p>Actual Price</p>
                        <p>{modalData.shift.actual_price}</p>
                      </div>
                      <div className="d-flex justify-content-between align-item-center">
                        <p>Price</p>
                        <p>{modalData.shift.price}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    <div className="card p-3">
                      <h5 className="font-weight-bold">Booking Summary</h5>
                      <hr />

                      <div className="d-flex justify-content-between align-item-center">
                        <p>Total</p>
                        <p>
                          $
                          {modalData.bookingdetails.payments.length > 0 &&
                            modalData.bookingdetails.payments[0].sub_total}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between align-item-center">
                        <p>Coupon Discount</p>
                        <p>
                          -$
                          {modalData.bookingdetails.payments.length > 0 &&
                            modalData.bookingdetails.payments[0]
                              .coupon_discount}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between align-item-center">
                        <p>Tax</p>
                        <p>
                          +$
                          {modalData.bookingdetails.payments.length > 0 &&
                            modalData.bookingdetails.payments[0].tax_amount}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between align-item-center">
                        <p>Points Used</p>
                        <p>
                          -$
                          {modalData.bookingdetails.payments.length > 0 &&
                            modalData.bookingdetails.payments[0].used_points}
                        </p>
                      </div>

                      <strong className="text-center mt-5">Grand Total</strong>
                      <div className="btn btn-lg btn-block btn-primary">
                        <strong>
                          $
                          {modalData.bookingdetails.payments.length > 0 &&
                            modalData.bookingdetails.payments[0].grant_total}
                        </strong>
                      </div>

                      <div className="text-center mt-3">
                        <Link to={`invoices/${modalData.bookingdetails.id}`}>
                          Go to invoice details
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>

          <div style={{ marginTop: "20px", width: "200px" }}>
            {!isEmpty(services) && (
              <InputSelect
                options={services}
                placeholder={"All services"}
                isLabel={false}
                getValue={(value) => this.selectByService(value)}
              />
            )}
          </div>
        </div>
        <div className={classes.content}>
          <CustomCalendar
            dateChange={(startDate, endDate) =>
              this.dateChange(startDate, endDate)
            }
            data={bookedSlots}
          />
          {/*<CalendarComponent data={bookedSlots}/>*/}
        </div>
        {isLoading && <CustomLoader isNoteOpen={true} />}
      </RootElement>
    );
  }
}

const mapStateToProps = (state) => {
  return state.app;
};

Booking = withStyles(styles)(Booking);

export default connect(mapStateToProps, null)(Booking);
