import React, { Component } from 'react';
import RootElement from "./component/RootElement";

class Settings extends Component {
    render() {
        return (
            <RootElement>
                <div>
                    Settings
                </div>
            </RootElement>
        )
    }
}

export default Settings;
