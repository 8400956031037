import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

function TableHead({ header }) {
  return (
    <thead>
      <tr>
        {header.map((header, index) => {
          return (
            <th style={{ textAlign: header.align }} scope="col" key={index}>
              {header.label}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

const CouponDetailsTable = ({
  header,
  couponDetails,
  openUpdateForm,
  handleDelete,
  goToDetails,
}) => {
  return (
    <table className="table">
      <TableHead header={header} />
      <tbody>
        {couponDetails.map((details, index) => {
          return (
            <tr key={index}>
              <td style={{ textAlign: "left" }}>{details.coupon.code}</td>
              <td style={{ textAlign: "left" }}>{details.coupon.title} $</td>
              <td style={{ textAlign: "left" }}>
                {details.coupon.off_percentage} %
              </td>
              <td
                style={{ textAlign: "left", cursor: "pointer" }}
                onClick={() => goToDetails(details.user)}
              >
                {details.user.firstname}
              </td>
              <td style={{ textAlign: "left" }}>
                <strong>
                  <Link to={`/invoices/${details.booking_detail_id}`}>
                    {details.booking_detail_id}
                  </Link>
                </strong>
              </td>
              <td style={{ textAlign: "left" }}>
                {moment(details.created_at).format("YYYY-MM-DD")}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
export default CouponDetailsTable;
