import API from "../../utils/api";

export const fetchBookingDetails = async (data) => {
    try {
        let response = await API.get(`/booking-details`, { params: data });
        return response.data;
    } catch (e) {
        throw e;
    }
};

export const fetchInvoices = async (data) => {
    try {
        let response = await API.get(`/invoices`, { params: data });
        return response.data;
    } catch (e) {
        throw e;
    }
};

export const requestToCancel = async (id, data) => {
    try {
        let response = await API.post(
            "/booking-details/request-cancel/" + id,
            data
        );
        return response.data;
    } catch (e) {
        throw e;
    }
};

export const confirmCancel = async (id, data) => {
    try {
        let response = await API.post(
            "/booking-details/cancel-confirm/" + id,
            data
        );
        return response.data;
    } catch (e) {
        throw e;
    }
};

export const fetchBookingDetailsById = async (id, data) => {
    try {
        let response = await API.get(`/booking-details/` + id, {
            params: data,
        });
        return response.data;
    } catch (e) {
        throw e;
    }
};

// Clear the booking and slots
export const cancelSlots = async (id, data) => {
    try {
        let response = await API.post("/booking/cancel/" + id, data);
        return response.data;
    } catch (e) {
        throw e;
    }
};

// This method is only for admin cancellation
export const cancelBookingByAdmin = async (payload) => {
    try {
        let response = await API.post(`/booking/cancel-by-admin/${payload.type}`, payload.data);
        return response.data;
    } catch (e) {
        throw e;
    }
};
