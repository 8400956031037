import React from "react";
import RootElement from "./component/RootElement";

// import { get } from "lodash";

// mui
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// icons
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

// Custom imports
// import { APP_NAME } from "../../store/constance/actionTypes";

import BarChart from "../../components/chart/BarChart";
import MakeupRoomBarChart from "../../components/chart/MakeupRoomBarChart";
import TotalCard from "../../components/cards/TotalCard";
import TopProduct from "../../components/cards/TopProduct";
import LatestNotificationsComponent from "../../components/cards/LatestNotifications";
import StudioSummaryCard from "../../components/cards/StudioSummaryCard";
import PlainInvoiceTable from "../../components/tables/PlainInvoiceTable";

// Services
import { fetchData } from "../../services/private/DashboardService";
import { fetchUsedCoupons } from "../../services/private/CouponService";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  paperSideSection: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    marginBottom: 10,
  },
  h2: {
    color: theme.palette.linen.primary,
  },
}));

const tableHeader = [
  {
    align: "left",
    key: "id",
    label: "Reference ID",
  },
  {
    align: "left",
    key: "date",
    label: "Booked date",
  },
  {
    align: "left",
    key: "photographer_name",
    label: "Photographer name",
  },
  {
    align: "left",
    key: "shoot_type",
    label: "Shoot type",
  },
  /*{
    align: "left",
    key: "No of slots",
    label: "No of slots",
  },*/
  {
    align: "left",
    key: "status",
    label: "Status",
  },
  {
    align: "left",
    key: "actions",
    label: "Action",
  },
];

function Dashboard(props) {
  const classes = useStyles();

  const [loading, setLoading] = React.useState({
    totalBookings: false,
    totalRevenue: false,
    topStudios: false,
    topShifts: false,
    bookingDetails: false,
    bookingSummary: false,
    studiosSummary: false,
    activeCoupons: false,
    usedCoupons: false,
    latestNotifications: false,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [day, setDay] = React.useState("Today");

  const handleClose = (value) => {
    if (day !== value) {
      setDay(value);
    }

    setAnchorEl(null);
  };

  const [activeCoupons, SetActiveCoupons] = React.useState(0);
  const fetchActiveCoupons = async () => {
    setLoading({
      totalBookings: true,
      totalRevenue: true,
      topStudios: true,
      topShifts: true,
      bookingDetails: true,
      bookingSummary: true,
      studiosSummary: true,
      activeCoupons: true,
      usedCoupons: true,
      latestNotifications: true,
    });
    const res = await fetchData("active-coupons", { filterBy: day });

    // update the state
    SetActiveCoupons(res.activeCoupons);
    setLoading({ ...loading, activeCoupons: false });
  };

  const [totalBookings, SetTotalBookings] = React.useState(0);
  const fetchTotalBookings = async () => {
    setLoading({
      totalBookings: true,
      totalRevenue: true,
      topStudios: true,
      topShifts: true,
      bookingDetails: true,
      bookingSummary: true,
      studiosSummary: true,
      activeCoupons: true,
      usedCoupons: true,
      latestNotifications: true,
    });
    // const res = await fetchData("bookings", { filterBy: day });

    const total =
      willo.totalBookings + birch.totalBookings + fern.totalBookings;

    // update the state
    SetTotalBookings(total);
    // SetTotalBookings(res.totalBookings);
    setLoading({ ...loading, totalBookings: false });
  };

  const [totalRevenue, SetTotalRevenue] = React.useState(0);
  const fetchTotalRevenue = async () => {
    setLoading({
      totalBookings: true,
      totalRevenue: true,
      topStudios: true,
      topShifts: true,
      bookingDetails: true,
      bookingSummary: true,
      studiosSummary: true,
      activeCoupons: true,
      usedCoupons: true,
      latestNotifications: true,
    });
    // const res = await fetchData("revenue", { filterBy: day });

    const total = willo.totalCost + birch.totalCost + fern.totalCost;

    // update the state
    SetTotalRevenue(total);
    // SetTotalRevenue(res.totalRevenue);
    setLoading({ ...loading, totalRevenue: false });
  };

  const [topStudios, SetTopStudios] = React.useState({
    name: "",
    branch: { name: "" },
  });
  const fetchTopStudios = async () => {
    setLoading({
      totalBookings: true,
      totalRevenue: true,
      topStudios: true,
      topShifts: true,
      bookingDetails: true,
      bookingSummary: true,
      studiosSummary: true,
      activeCoupons: true,
      usedCoupons: true,
      latestNotifications: true,
    });
    const res = await fetchData("studios", { filterBy: day });

    // update the state
    SetTopStudios(res.topStudios);
    setLoading({ ...loading, topStudios: false });
  };

  const [topShifts, SetTopShifts] = React.useState({
    name: "",
    start_time: "",
  });
  const fetchTopShifts = async () => {
    setLoading({
      totalBookings: true,
      totalRevenue: true,
      topStudios: true,
      topShifts: true,
      bookingDetails: true,
      bookingSummary: true,
      studiosSummary: true,
      activeCoupons: true,
      usedCoupons: true,
      latestNotifications: true,
    });
    const res = await fetchData("shifts", { filterBy: day });

    // update the state
    SetTopShifts(res.topShifts);
    setLoading({ ...loading, topShifts: false });
  };

  const [bookingDetails, setBookingDetails] = React.useState([]);
  const fetchLatestBookingDetails = async () => {
    setLoading({
      totalBookings: true,
      totalRevenue: true,
      topStudios: true,
      topShifts: true,
      bookingDetails: true,
      bookingSummary: true,
      studiosSummary: true,
      activeCoupons: true,
      usedCoupons: true,
      latestNotifications: true,
    });
    const res = await fetchData("latest-bookings", { filterBy: day });

    setBookingDetails(res.latestBookings);
    setLoading({ ...loading, bookingDetails: false });
  };

  const [bookingSummary, setBookingSummary] = React.useState([]);
  const fetchBookingSummary = async () => {
    setLoading({
      totalBookings: true,
      totalRevenue: true,
      topStudios: true,
      topShifts: true,
      bookingDetails: true,
      bookingSummary: true,
      studiosSummary: true,
      activeCoupons: true,
      usedCoupons: true,
      latestNotifications: true,
    });
    const res = await fetchData("booking-summary", {
      filterBy: day,
      category: "studio",
    });

    setBookingSummary(res.bookingSummary);
    setLoading({ ...loading, bookingSummary: false });
  };

  const [makeupRoomBookingSummary, setMakeupRoomBookingSummary] =
    React.useState([]);
  const fetchmakeupRoomBookingSummary = async () => {
    setLoading({
      totalBookings: true,
      totalRevenue: true,
      topStudios: true,
      topShifts: true,
      bookingDetails: true,
      bookingSummary: true,
      studiosSummary: true,
      activeCoupons: true,
      usedCoupons: true,
      latestNotifications: true,
    });
    const res = await fetchData("booking-summary", {
      filterBy: day,
      category: "makeup_room",
    });
    setMakeupRoomBookingSummary(res.bookingSummary);
    // setLoading({ ...loading, bookingSummary: false });
  };

  // const [studiosSummary, setStudiosSummary] = React.useState([]);

  const [willo, setWillo] = React.useState({
    totalBookings: 0,
    totalCost: 0,
  });

  const [birch, setBirch] = React.useState({
    totalBookings: 0,
    totalCost: 0,
  });

  const [fern, setFern] = React.useState({
    totalBookings: 0,
    totalCost: 0,
  });

  const [oro, setOro] = React.useState({
    totalBookings: 0,
    totalCost: 0,
  });

  const fetchStudiosSummary = async () => {
    setLoading({
      totalBookings: true,
      totalRevenue: true,
      topStudios: true,
      topShifts: true,
      bookingDetails: true,
      bookingSummary: true,
      studiosSummary: true,
      activeCoupons: true,
      usedCoupons: true,
      latestNotifications: true,
    });

    const res = await fetchData("studios-summary", {
      filterBy: day,
      category: "studio",
    });

    const data = res.data;

    for (let x in data) {
      switch (x) {
        case "Willo":
          setWillo({
            totalBookings: data[x][0].totalBookings,
            totalCost: data[x][0].totalCost,
          });

          break;
        case "Birch":
          setBirch({
            totalBookings: data[x][0].totalBookings,
            totalCost: data[x][0].totalCost,
          });

          break;
        case "Fern":
          setFern({
            totalBookings: data[x][0].totalBookings,
            totalCost: data[x][0].totalCost,
          });
          break;
        case "Oro":
          setOro({
            totalBookings: data[x][0].totalBookings,
            totalCost: data[x][0].totalCost,
          });

          break;
        default:
        // code block
      }
    }
    // setStudiosSummary(res.data);
    setLoading({ ...loading, studiosSummary: false });
  };

  const [usedCoupons, setUsedCoupons] = React.useState([]);
  const _fetchUsedCoupons = async () => {
    setLoading({
      totalBookings: true,
      totalRevenue: true,
      topStudios: true,
      topShifts: true,
      bookingDetails: true,
      bookingSummary: true,
      studiosSummary: true,
      activeCoupons: true,
      usedCoupons: true,
      latestNotifications: true,
    });

    const res = await fetchUsedCoupons(5);

    setUsedCoupons(res.data);
    setLoading({ ...loading, bookingSummary: false });
  };

  const [latestNotifications, setLatestNotifications] = React.useState([]);
  const fetchLatestNotifications = async () => {
    setLoading({
      totalBookings: true,
      totalRevenue: true,
      topStudios: true,
      topShifts: true,
      bookingDetails: true,
      bookingSummary: true,
      studiosSummary: true,
      activeCoupons: true,
      usedCoupons: true,
      latestNotifications: true,
    });
    const res = await fetchData("latestNotifications", {
      filterBy: day,
    });


    const mpa = res.notifications.map((notification) => {
      return {
        ...notification,
        data: JSON.parse(notification.data),
      };
    });

    console.log(`mpa`, mpa);

    setLatestNotifications(mpa);
    setLoading({ ...loading, latestNotifications: false });
  };

  React.useEffect(() => {
    setLoading({
      totalBookings: true,
      totalRevenue: true,
      topStudios: true,
      topShifts: true,
      bookingDetails: true,
      bookingSummary: true,
      studiosSummary: true,
      activeCoupons: true,
      usedCoupons: true,
      latestNotifications: true,
    });

    setTimeout(() => {
      fetchStudiosSummary();
      fetchTopStudios();
      fetchTopShifts();
      fetchLatestBookingDetails();
      fetchBookingSummary();
      fetchmakeupRoomBookingSummary();
      fetchActiveCoupons();
      _fetchUsedCoupons();
      fetchLatestNotifications();
      //
    }, 1000);
  }, [day]);

  React.useEffect(() => {
    fetchTotalBookings();
    fetchTotalRevenue();
  }, [willo, birch, fern, oro]);

  return (
    <RootElement>
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <Grid item xs={12}>
            <div className="d-flex align-item-center mb-3">
              <strong>{day} performance</strong>
              <div className="pl-3">
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  {day} <KeyboardArrowDownIcon />
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => handleClose(day)}
                >
                  <MenuItem onClick={() => handleClose("Today")}>
                    Today
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("Weekly")}>
                    Weekly
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("Monthly")}>
                    Monthly
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("Yearly")}>
                    Yearly
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </Grid>
          <Grid container spacing={1} className="mb-5">
            {/* Rooms */}
            <Grid item xs={12} md={3}>
              <Paper className={classes.paper}>
                <StudioSummaryCard
                  label="Willo"
                  totalBookings={willo.totalBookings}
                  totalCost={willo.totalCost}
                  loading={loading.studiosSummary}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper className={classes.paper}>
                <StudioSummaryCard
                  label="Birch"
                  totalBookings={birch.totalBookings}
                  totalCost={birch.totalCost}
                  loading={loading.studiosSummary}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper className={classes.paper}>
                <StudioSummaryCard
                  label="Fern"
                  totalBookings={fern.totalBookings}
                  totalCost={fern.totalCost}
                  loading={loading.studiosSummary}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper className={classes.paper}>
                <StudioSummaryCard
                  label="Oro"
                  totalBookings={oro.totalBookings}
                  totalCost={oro.totalCost}
                  loading={loading.studiosSummary}
                />
              </Paper>
            </Grid>

            {/*details*/}
            <Grid item xs={12} md={3}>
              <Paper className={classes.paper}>
                <TotalCard
                  label="Total Bookings"
                  qty={totalBookings}
                  loading={loading.totalBookings}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper className={classes.paper}>
                <TotalCard
                  label="Total Cost"
                  qty={`$${totalRevenue}`}
                  loading={loading.totalRevenue}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper className={classes.paper}>
                <TotalCard
                  label="Active Coupons"
                  qty={activeCoupons}
                  loading={loading.activeCoupons}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper className={classes.paper}>
                <TotalCard label="Others" qty="27" loading={false} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} className="pt-5">
              <Paper className={classes.paperSideSection}>
                <TopProduct
                  label="Top Selling Service"
                  name={topStudios.name}
                  otherData={topStudios.branch.name}
                  loading={loading.topStudios}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} className="pt-5">
              <Paper className={classes.paperSideSection}>
                <TopProduct
                  label="Top Selling Time"
                  name={topShifts.name}
                  otherData={topShifts.start_time}
                  loading={loading.topShifts}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} className=" mt-2 pt-5">
          <Paper className={classes.paperSideSection}>
            <LatestNotificationsComponent
              label="Notifications"
              notifications={latestNotifications}
              loading={loading.latestNotifications}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} style={{ padding: 5 }}>
          <BarChart payload={bookingSummary} />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: 50 }}></Grid>

        <Grid item xs={12} style={{ padding: 5, marginBottom: 30 }}>
          <div className="mb-3">
            <strong>Latest bookings</strong>
          </div>
          <PlainInvoiceTable
            goToDetails={(data) => props.history.push("/invoices/" + data.id)}
            header={tableHeader}
            bookingDetails={bookingDetails}
            loading={loading.bookingDetails}
          />
        </Grid>

        <Grid item xs={12} style={{ padding: 5 }}>
          <MakeupRoomBarChart payload={makeupRoomBookingSummary} />
        </Grid>
      </Grid>
    </RootElement>
  );
}

export default Dashboard;
