import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import InputText from "../core/InputText/InputText";

const useStyles = makeStyles((theme) => ({
	footer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		"&>:first-child": {
			marginRight: "12px",
		},
	},
}));

const numPattern = /^[0-9]*$/;

const GrantPointsForm = (props) => {
	const classes = useStyles();
	const { initialValues, errors } = props;
	const formik = useFormik({
		initialValues: {
			points: "",
			reason: "",
		},
		validationSchema: Yup.object({
			points: Yup.string()
				.matches(numPattern, "The value should be number")
				.max(3, "too long")
				.required("Required"),
			reason: Yup.string().required("Description is equired"),
		}),
		onSubmit: (values) => {
			const { getFormValue } = props;
			if (getFormValue) {
				getFormValue(values);
			}

			// console.log(`values`, values);
		},
	});
	// const getInputValue=event=>{
	//     formik.setFieldValue(event.target.name, event.target.value);
	// }
	return (
		<div style={{ padding: "24px", paddingTop: "0" }}>
			<form onSubmit={formik.handleSubmit}>
				<div className="form-row">
					<div className=" col-md-6">
						<InputText
							id={"points"}
							label={"Points"}
							placeholder={"Enter the points"}
							value={formik.values.points}
							error={
								Boolean(formik.errors.points) &&
								formik.touched.points
							}
							errorMessage={formik.errors.points}
							getValue={(value) =>
								formik.setFieldValue("points", value)
							}
							onBlur={() => formik.setFieldTouched("points")}
						/>
					</div>

					<div className=" col-md-12">
						<InputText
							id={"reason"}
							label={"Description"}
							placeholder={"Enter here"}
							value={formik.values.reason}
							error={
								Boolean(formik.errors.reason) &&
								formik.touched.reason
							}
							errorMessage={formik.errors.reason}
							getValue={(value) =>
								formik.setFieldValue("reason", value)
							}
							onBlur={() => formik.setFieldTouched("reason")}
						/>
					</div>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						width: "100%",
						color: "red",
						padding: "16px",
					}}
				>
					{Object.keys(errors).map((key) => {
						return (
							<div>
								{errors[key].map((error) => {
									return <p>{error}</p>;
								})}
							</div>
						);
					})}
				</div>
				<div className={classes.footer}>
					<button
						onClick={props.handleClose && props.handleClose}
						className="btn btn-danger"
					>
						Cancel
					</button>
					<button type={"submit"} className="btn btn-success">
						Save
					</button>
				</div>
			</form>
		</div>
	);
};

export default GrantPointsForm;
