import { SET_CUSTOMERS, SET_CUSTOMER } from "../constance/actionTypes";

const initialState = {
	loading: false,
	editingMode: false,
	customers: [],
	customer: {},
};

const customerReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_CUSTOMERS:
			return {
				...state,
				customers: action.payload.customers,
			};
		case SET_CUSTOMER:
			return {
				...state,
				customer: action.payload.customer,
				editingMode: action.payload.editingMode,
			};
		default:
			return state;
	}
};

export default customerReducer;
