import React, {Component} from 'react';
import RootElement from "./component/RootElement";

class BranchDetails extends Component {
    render() {
        return (
            <RootElement>
                <div>
                    branch details
                </div>
            </RootElement>
        )
    }
}

export default BranchDetails;
