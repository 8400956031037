import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  ul: {
    "& .Mui-selected": {
      color: "#fff",
      backgroundColor: theme.palette.linen.themeColor,
    },
  },
}));

export default function PaginationComponent({ data, setCurrentPage }) {
  const classes = useStyles();

  // console.log(`data`, data);

  return (
    <div className={classes.root}>
      <Pagination
        className={classes.ul}
        shape="rounded"
        size="large"
        count={data.links.length - 2}
        defaultPage={data.current_page}
        page={data.current_page}
        onChange={(event, page) => setCurrentPage(page)}
      />
    </div>
  );
}
