import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import Swal from "sweetalert2";

// mui
import { makeStyles } from "@material-ui/core/styles";

// Components
import InputText from "../core/InputText/InputText";
import InputSelect from "../core/InputSelect/InputSelect";

const useStyles = makeStyles((theme) => ({
	footer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		"&>:first-child": {
			marginRight: "12px",
		},
	},
}));

const paymentStatus = [
	{
		label: "Unpaid",
		value: "Unpaid",
	},
	{
		label: "Paid",
		value: "Paid",
	},
];

const paymentMethods = [
	{ label: "Cash", value: "Cash" },
	{ label: "Cheque", value: "Cheque" },
	{ label: "Credit Card", value: "Credit Card" },
	{ label: "Debit Card", value: "Debit Card" },
	{ label: "E-Transfer", value: "E-Transfer" },
];

const numPattern = /^[0-9]*$/;

const CreateInvoiceForm = (props) => {
	const classes = useStyles();

	const [items, setItems] = React.useState([]);

	const { initialValues, errors } = props;
	const formik = useFormik({
		initialValues: {
			description: "",
			cost: 0,
			payment_status: "Unpaid",
			payment_method: "Credit Card",
			tax_amount: 13,
			sub_total: 0,
			grant_total: 0,
			discount: 0,
		},
		validationSchema: Yup.object({
			description: Yup.string().required("Description is required"),
			cost: Yup.string()
				.matches(numPattern, "The cost should be number")
				.required("Required"),
			payment_status: Yup.string().required("Required"),
			payment_method: Yup.string().required("Required"),
			tax_amount: Yup.string()
				.matches(numPattern, "The value should be number")
				.required("Required"),
			sub_total: Yup.string()
				.matches(numPattern, "The value should be number")
				.required("Required"),
			grant_total: Yup.string()
				.matches(numPattern, "The value should be number")
				.required("Required"),
			discount: Yup.string()
				.matches(numPattern, "The value should be number")
				.required("Required"),
		}),
		onSubmit: async (values) => {
			if (values.payment_status === "default") {
				await Swal.fire(
					"Error",
					"Please select a payment status",
					"error"
				);
				return;
			}

			if (values.payment_method === "default") {
				await Swal.fire(
					"Error",
					"Please select a payment method",
					"error"
				);
				return;
			}

			const { getFormValue } = props;

			if (getFormValue) {
				console.log(`getFormValue`, getFormValue);
				// getFormValue(values);
			}
		},
	});

	const [invoice, setInvoice] = React.useState({
		grant_total: 0,
		discount: 0,
		tax_amount: 0,
	});

	const calculate = async () => {
		if (items.length > 0) {
			const totalFromItems = items.reduce(
				(n, { cost }) => parseFloat(n) + parseFloat(cost),
				0
			);

			formik.setFieldValue("sub_total", totalFromItems);

			let grant_total = totalFromItems;
			let discount = invoice.discount;
			let tax_amount = invoice.tax_amount;

			let discountPercentage = parseFloat(formik.values.discount);
			let taxPercentage = parseFloat(formik.values.tax_amount);

			if (discountPercentage !== "") {
				discount = (grant_total / 100) * discountPercentage;
			}

			grant_total = grant_total - parseFloat(discount);

			if (taxPercentage !== "") {
				tax_amount = ((grant_total / 100) * taxPercentage).toFixed(2);
			}

			grant_total = grant_total + parseFloat(tax_amount);

			setInvoice({
				grant_total: parseFloat(grant_total).toFixed(2),
				discount: parseFloat(discount).toFixed(2),
				tax_amount: parseFloat(tax_amount).toFixed(2),
			});
		} else {
			formik.setFieldValue("sub_total", 0);
			setInvoice({ grant_total: 0, discount: 0, tax_amount: 0 });
		}
	};

	React.useEffect(() => {
		calculate();
	}, [
		items,
		formik.values.discount,
		formik.values.tax_amount,
		formik.values.sub_total,
	]);

	const [itemsError, setItemsError] = React.useState(false);

	const handleSetItemData = async () => {
		const payload = {
			description: formik.values.description,
			cost: formik.values.cost,
		};

		let _items = items;

		const found = items.find(
			(item) => item.description === payload.description
		);

		if (found) {
			setItemsError(true);
			return;
		}

		setItemsError(false);

		_items.push(payload);

		setItems(_items);

		// reset the formik value
		formik.setFieldValue("description", "");
		formik.setFieldValue("cost", 0);

		calculate();
	};

	function deleteItem(key) {
		const _items = items.filter((item, index) => index !== key);

		setItems(_items);
	}

	function _handleSubmit() {
		// final payload for invoice creation
		const payload = {
			items,
			...invoice,
			sub_total: formik.values.sub_total,
			payment_status:
				formik.values.payment_status === "Paid" ? "SUCCESS" : "DECLINE",
			payment_type:
				formik.values.payment_status === "Paid"
					? formik.values.payment_method
					: "ONLINE",
		};

		props.getFormValue(payload);
	}

	return (
		<div style={{ padding: "24px", paddingTop: "0" }}>
			<form onSubmit={formik.handleSubmit}>
				<div className="form-row">
					<h3>Create New Invoice</h3>
					<table className="table">
						<thead>
							<th style={{ textAlign: "left" }}>Description</th>
							<th style={{ textAlign: "left" }}>Cost</th>
						</thead>
						<tbody>
							{items &&
								items.map((item, index) => (
									<tr key={index}>
										<td style={{ textAlign: "left" }}>
											{item.description}
										</td>
										<td style={{ textAlign: "left" }}>
											<div className="d-flex align-items-center">
												<p className="m-0">
													${item.cost}
												</p>
												<a
													className="text-danger pt-1 pl-2"
													style={{
														cursor: "pointer",
													}}
													onClick={() =>
														deleteItem(index)
													}
												>
													<i className="material-icons">
														delete
													</i>
												</a>
											</div>
										</td>
									</tr>
								))}
							<tr>
								<td style={{ textAlign: "left" }}></td>
								<td style={{ textAlign: "left" }}></td>
							</tr>
						</tbody>
						{itemsError && (
							<p
								className="m-0 p-0"
								style={{
									color: "#f00",
									fontSize: 13,
								}}
							>
								Seem like you already added the item
							</p>
						)}
					</table>

					<hr style={{ marginBottom: 50 }} />

					<div className="col-md-12 d-flex align-item-center border rounded mb-3">
						<div className="col-md-7 pl-0 ml-0">
							<InputText
								id={"description"}
								label={"Description"}
								placeholder={"Enter here"}
								value={formik.values.description}
								error={
									Boolean(formik.errors.description) &&
									formik.touched.description
								}
								errorMessage={formik.errors.description}
								getValue={(value) =>
									formik.setFieldValue("description", value)
								}
								onBlur={() => formik.setFieldTouched("")}
							/>
						</div>

						<div className="col-md-3">
							<InputText
								id={"sub-total"}
								label={"Cost"}
								placeholder={"Enter the cost"}
								value={formik.values.cost}
								error={
									Boolean(formik.errors.cost) &&
									formik.touched.cost
								}
								errorMessage={formik.errors.cost}
								getValue={(value) =>
									formik.setFieldValue("cost", value)
								}
								onBlur={() => formik.setFieldTouched("cost")}
							/>
						</div>

						<div className="col-md-2">
							<button
								className="btn btn-success"
								type="button"
								onClick={() => handleSetItemData()}
							>
								Add
							</button>
						</div>
					</div>

					<div className="col-md-6">
						<div className="col-md-8 pl-0 ml-0">
							<InputText
								id={"discount-total"}
								label={"Discount (%)"}
								placeholder={"Enter the discount amount"}
								value={formik.values.discount}
								error={
									Boolean(formik.errors.discount) &&
									formik.touched.discount
								}
								errorMessage={formik.errors.discount}
								getValue={(value) =>
									formik.setFieldValue("discount", value)
								}
								onBlur={() =>
									formik.setFieldTouched("discount")
								}
							/>
						</div>

						<div className="col-md-8 pl-0 ml-0">
							<InputText
								id={"tax"}
								label={"Tax (%)"}
								placeholder={"Enter the tax"}
								value={formik.values.tax_amount}
								error={
									Boolean(formik.errors.tax_amount) &&
									formik.touched.tax_amount
								}
								errorMessage={formik.errors.tax_amount}
								getValue={(value) =>
									formik.setFieldValue("tax_amount", value)
								}
								onBlur={() =>
									formik.setFieldTouched("tax_amount")
								}
							/>
						</div>

						<div className="col-md-8 pl-0 ml-0">
							<InputSelect
								selected={formik.initialValues.payment_status}
								options={paymentStatus}
								placeholder={"Please select a payment status"}
								label={"Payment Status"}
								getValue={(value) =>
									formik.setFieldValue(
										"payment_status",
										value
									)
								}
							/>

							{Boolean(formik.errors.payment_status) &&
								formik.errors.tax_amount}
						</div>

						{formik.values.payment_status === "Paid" && (
							<div className="col-md-8 pl-0 ml-0">
								<InputSelect
									selected={
										formik.initialValues.payment_method
									}
									options={paymentMethods}
									placeholder={
										"Please select a payment method."
									}
									label={"Payment Method"}
									getValue={(value) =>
										formik.setFieldValue(
											"payment_method",
											value
										)
									}
								/>
							</div>
						)}
					</div>

					<div className="col-md-6 ">
						<div className="text-right pt-3">
							<div className="d-flex justify-content-between align-item-center">
								<p>Sub Total</p>
								<p>${formik.values.sub_total}</p>
							</div>
							{/*<div className="d-flex justify-content-between align-item-center">
								<p>Grand Total</p>
								<p>${formik.values.grant_total}</p>
							</div>*/}
							<div className="d-flex justify-content-between align-item-center">
								<p>Discount</p>
								<p>-${invoice.discount}</p>
							</div>
							<div className="d-flex justify-content-between align-item-center">
								<p>Tax</p>
								<p>+${invoice.tax_amount}</p>
							</div>

							<hr />

							<div className="d-flex justify-content-between align-item-center">
								<h5>Total ($)</h5>
								<h5>${invoice.grant_total}</h5>
							</div>
						</div>
					</div>
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						width: "100%",
						color: "red",
						padding: "16px",
					}}
				>
					{items.length <= 0 && (
						<p>
							You must have at least 1 item to create an invoice
						</p>
					)}
				</div>

				<div className={classes.footer}>
					<button
						onClick={props.handleClose && props.handleClose}
						className="btn btn-danger"
					>
						Cancel
					</button>
					<button
						type={"button"}
						className="btn btn-success"
						disabled={items.length <= 0 ? true : false}
						onClick={() => _handleSubmit()}
					>
						Create
					</button>
				</div>
			</form>
		</div>
	);
};

export default CreateInvoiceForm;
