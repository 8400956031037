import React, { Component } from "react";

import Swal from "sweetalert2";

// material-ui
import { Divider, withStyles } from "@material-ui/core";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// components
import RootElement from "../component/RootElement";
import CustomLoader from "../../../components/CustomLoader";
import AlertBar from "../../../components/core/Alert/AlertBar";
// import CustomDialog from "../../../components/core/CustomDialog";
import TextArea from "../../../components/core/InputText/TextArea";
import ImageUploader from "../../../components/ImageUploader";

// services
import {
  fetchPageDetails,
  updateAboutText,
  uploadImage,
  deleteImage,
} from "../../../services/private/PageSettingsService";

const styles = (theme) => ({
  shifts: {
    display: "flex",
    flexWrap: "wrap",
  },
  discounts: {
    display: "flex",
    minWidth: "600px",
  },
  singleCart: {
    display: "flex",
    flexWrap: "wrap",
    flex: "50%",
    height: "100%",
    padding: 12,
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      flex: "100%",
    },
  },
  serviceDetails: {
    padding: "24px",
  },
  detail: {
    display: "flex",
    flexDirection: "row",
    fontSize: "17px",
    alignItems: "center",
    "& > :first-child": {
      width: "180px",
    },
  },
  badge: {
    color: "#fff",
    backgroundColor: "#009688",
    padding: "6px  0px",
    width: 180,
    paddingLeft: 8,
    textAlign: "left",
    borderRadius: 4,
  },
});

class PageSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initData: [],
      serviceId: null,
      isLoading: false,
      alertOpen: false,
      alertMessage: "",
      alertType: "",
      serviceDetails: {},
      isShowDiscountDetails: true,
      isShowServiceDetails: true,
      isShowPageDetails: true,

      isAddDiscount: false,
      isEditDiscount: false,
      discounts: [],
      discount: {},
      isShowShiftDetails: true,
      errors: {},

      pageDetails: {},

      formData: {
        about_text: "",
      },
    };
  }

  componentDidMount = async () => {
    const {
      match: { params },
    } = this.props;

    this.setState({ isLoading: true });

    console.log(`params`, params.pageName);

    try {
      const res = await fetchPageDetails(params.pageName);
      this.setState({ pageDetails: res.data, isLoading: false });
    } catch (e) { }
  };

  setLoading = (value) => {
    this.setState({ isLoading: value });
  };

  saveAboutText = async () => {
    this.setState({ isLoading: true });
    const response = await updateAboutText({
      id: this.state.pageDetails.id,
      ...this.state.formData,
    });

    this.setState({ isLoading: false, pageDetails: response.data });
  };

  uploadImages = async (images) => {
    // console.log("uploadImages", images);

    const {
      match: { params },
    } = this.props;

    this.setLoading(true);

    if (images.length > 0) {
      for (var i = 0; i < images.length; i++) {
        // set the payload
        const payload = {
          id: this.state.pageDetails.id,
          location: this.state.pageDetails.page_name,
          image: images[i].data_url,
        };

        await uploadImage(payload);
      }
    }

    const response = await fetchPageDetails(params.pageName);

    this.setState({
      isLoading: false,
      pageDetails: response.data,
    });
  };

  deleteImageConfirm = async (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "text",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        // resolve();
        this.deleteImage(data);
      }
    });
  };

  deleteImage = async (data) => {
    this.setLoading(true);

    const response = await deleteImage({
      ...data,
      id: this.state.pageDetails.id,
    });

    this.setState({
      isLoading: false,
      pageDetails: response.data,
    });
  };

  confirm = async (text) => {
    return Promise((resolve) => {
      Swal.fire({
        title: "Are you sure?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          resolve();
        }
      });
    });
  };

  render() {
    const { classes } = this.props;
    const {
      initData,
      isLoading,
      alertOpen,
      alertMessage,
      serviceDetails,
      alertType,
      isShowServiceDetails,
      isShowPageDetails,
      isShowDiscountDetails,
      isEditDiscount,
      isOpen,
      isAddDiscount,
      discounts,
      discount,
      isShowShiftDetails,
      errors,
      isShowHtmlTag,
      pageDetails,
    } = this.state;
    return (
      <RootElement>
        <div className={classes.serviceDetails}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() =>
              this.setState({
                isShowPageDetails: !this.state.isShowPageDetails,
              })
            }
          >
            <h3>Page details</h3>
            {isShowPageDetails ? <ExpandMoreIcon /> : <ExpandLessIcon />}

            <button
              style={{ margin: "0px" }}
              onClick={() => this.saveAboutText()}
              className="btn btn-success"
            >
              Save
            </button>
          </div>

          <Divider style={{ margin: "4px 0px" }} />
          {isShowPageDetails && (
            <div className="" style={{ padding: "12px 0 0 12px" }}>
              <TextArea
                label="About Text"
                value={pageDetails.about_text}
                getValue={(text) =>
                  this.setState({
                    ...this.state.formData,
                    formData: { about_text: text },
                  })
                }
              />
              <div className="my-3">
                <div style={{ marginTop: 20 }}>
                  <h5>Slider Images</h5>
                </div>
                {pageDetails && (
                  <div className="row">
                    {pageDetails.images &&
                      pageDetails.images.map((image, index) => (
                        <div key={index} className="col-4 image-item mb-1">
                          <img
                            src={`${process.env.REACT_APP_API}/storage/${image.path}`}
                          />

                          <div className="image-item__btn-wrapper">
                            <button
                              className="image-uploader-button text-danger"
                              onClick={() => this.deleteImageConfirm(image)}
                            >
                              <i class="bi bi-trash3"></i>
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>

              <div style={{ marginTop: 20 }}>
                <h5>Upload Slider Images</h5>
              </div>

              <div>
                <ImageUploader
                  serviceDetails={serviceDetails}
                  handleClick={(data) => this.uploadImages(data)}
                />
              </div>
            </div>
          )}
        </div>

        {isLoading && <CustomLoader isNoteOpen={true} />}

        {alertOpen && (
          <AlertBar
            alertMessage={alertMessage}
            alertType={alertType}
            onClose={() => this.setState({ alertOpen: false })}
          />
        )}
      </RootElement>
    );
  }
}

export default withStyles(styles)(PageSettings);
