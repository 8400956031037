import React, { useState } from "react";
import { isEmpty } from "lodash";

import moment from "moment";

// mui
import EditIcon from "@material-ui/icons/Edit";

// components
import MultipleSelect from "../core/MultipleSelect/MultipleSelect";

function TableHead({ header }) {
	return (
		<thead>
			<tr>
				{header.map((header, index) => {
					return (
						<th
							style={{ textAlign: header.align }}
							scope="col"
							key={index}
						>
							{header.label}
						</th>
					);
				})}
			</tr>
		</thead>
	);
}

const CustomersTable = ({ header, tableDatas, goToDetails }) => {
	const [values, setValues] = useState(false);

	return (
		<table className="table">
			<TableHead header={header} />
			<tbody>
				{tableDatas.map((data, index) => {
					return (
						<tr key={index}>
							<td
								onClick={() => goToDetails(data)}
								style={{ textAlign: "left", cursor: "pointer" }}
							>
								{data.firstname} {data.lastname}
							</td>
							<td style={{ textAlign: "left" }}>
								{data.lastname}
							</td>
							<td style={{ textAlign: "left" }}>{data.email}</td>
							<td style={{ textAlign: "left" }}>
								{data.member_ship.name}
							</td>
							<td style={{ textAlign: "left" }}>
								{moment(data.created_at).format(
									"YYYY-MM-DD HH:ss"
								)}
							</td>

							<td style={{ textAlign: "left" }}>
								<EditIcon
									onClick={() => goToDetails(data)}
									color="action"
									style={{ cursor: "pointer", margin: 2 }}
								/>
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};
export default CustomersTable;
