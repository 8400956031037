import React from "react";
import { Link } from "react-router-dom";

import moment from "moment";

// mui
// import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

// const useStyles = makeStyles((theme) => ({
// 	h2: {
// 		color: theme.palette.linen.primary,
// 	},
// }));

function Bookings(props) {
		console.log(`props`, props);
	return (
		<Link to={`/invoices/${props.notification.data.id}`}>
			{props.notification.msg}
		</Link>
	);
}

function Coupon(props) {

	return (
		<Link to={`/coupon/${props.notification.data.coupon_id}`}>
			{props.notification.msg}
		</Link>
	);
}

function LatestNotifications(props) {
	// console.log(`usedCoupons`, props);
	// const classes = useStyles();
	console.log(`props.notifications`, props.notifications);
	if (props.loading) {
		return (
			<div>
				<Skeleton animation="wave" variant="text" width={100} />
				<Skeleton
					animation="wave"
					variant="rect"
					width={400}
					height={300}
				/>
			</div>
		);
	}

	return (
		<div className="pt-1" style={{ height: 410 }}>
			<h5>{props.label}</h5>

			{props.notifications && props.notifications.length > 0 ? (
				<table className="table mt-3">
					<tr>
						<th>SN</th>
						<th>Msg</th>
						<th>At</th>
					</tr>
					{props.notifications.map((notification, index) => (
						<tr key={index} className="latest-notification">
							<td>{index + 1}</td>
							<td style={{ textAlign: "left" }}>
								{/*<Link
									to={`/invoices/${coupon.booking_detail_id}`}
								>
									{coupon.booking_detail_id}
								</Link>*/}
								{notification.type === "BOOKING" && (
									<Bookings notification={notification} />
								)}

								{notification.type === "CANCELLED_BOOKING" && (
									<Bookings notification={notification} />
								)}

								{notification.type === "COUPON_USED" && (
									<Coupon notification={notification} />
								)}
							</td>
							<td>{moment(notification.created_at).fromNow()}</td>
						</tr>
					))}
				</table>
			) : (
				<div className="text-center py-5">
					<strong>There's no latest notifications</strong>
				</div>
			)}
		</div>
	);
}

export default LatestNotifications;
