import API from '../../utils/api';

export const searchUser = async (data) => {
    try{
        let users = await API.get('/users/search',{params:data});
        return users.data;
    }catch(e){
        throw e;
    }
};

export const updateUser = async (data) => {
    try {
        let user = await API.put("/user/" + data.id, data);
        return user.data;
    } catch (e) {
        throw e;
    }
};

export const grantPoints = async (payload) => {
  let response = await API.post(`/user/points/grant`, payload);
  return response.data;
};

export const fetchCoupons = async (id) => {
  let response = await API.get(`/user/${id}/coupons`);
  return response.data;
};
