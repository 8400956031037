import { UPDATE_COUPON } from "../constance/actionTypes.js";

let initialState = {
  showModal: false,
  couponDetails: {},
};

const couponReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_COUPON:
      console.log(`coupon reducer => payload`, action.payload);
      return {
        ...initialState,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default couponReducer;
