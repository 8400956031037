import React from "react";

// mui
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
	h2: {
		color: theme.palette.linen.primary,
	},
}));

function StudioSummaryCard(props) {
	const classes = useStyles();

	if (props.loading) {
		return (
			<div>
				<Skeleton animation="wave" variant="text" width={100} />
				<Skeleton
					animation="wave"
					variant="rect"
					width={150}
					height={65}
				/>
			</div>
		);
	}

	return (
		<div>
			<h3 className={classes.h2}>{props.label}</h3>
			<h5>Total Bookings: {props.totalBookings}</h5>
			<h5>Total Cost: ${props.totalCost}</h5>
		</div>
	);
}

export default StudioSummaryCard;
