import React, { Component } from "react";

import moment from "moment";
import Swal from "sweetalert2";
import { chain, cloneDeep, findIndex, isEmpty } from "lodash";

// services
import {
  addDiscount,
  deleteDiscount,
  fetchDiscountByService,
  updateDiscount,
} from "../../services/DiscountService";
import {
  getServiceById,
  updateHtmlTags,
  updateAboutText,
  uploadImage,
  deleteImage,
} from "../../services/private/ServicesService";
import {
  addNewShift,
  deleteShift,
  fetchShiftByServiceId,
  updateShift,
} from "../../services/private/ShiftService";

// material-ui
import { Divider, withStyles } from "@material-ui/core";

// components
import RootElement from "./component/RootElement";
import ShiftDynamicForm from "../../components/forms/ShiftDynamicForm";
import CustomLoader from "../../components/CustomLoader";
import AlertBar from "../../components/core/Alert/AlertBar";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DiscountForm from "../../components/forms/DiscountForm";
import CustomDialog from "../../components/core/CustomDialog";
import TextArea from "../../components/core/InputText/TextArea";
import DiscountDetails from "../../components/molecule/DiscountDetails";
import ServiceHtmlTagForm from "../../components/forms/ServiceHtmlTagForm";
import ImageUploader from "../../components/ImageUploader";

const styles = (theme) => ({
  shifts: {
    display: "flex",
    flexWrap: "wrap",
  },
  discounts: {
    display: "flex",
    minWidth: "600px",
  },
  singleCart: {
    display: "flex",
    flexWrap: "wrap",
    flex: "50%",
    height: "100%",
    padding: 12,
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      flex: "100%",
    },
  },
  serviceDetails: {
    padding: "24px",
  },
  detail: {
    display: "flex",
    flexDirection: "row",
    fontSize: "17px",
    alignItems: "center",
    "& > :first-child": {
      width: "180px",
    },
  },
  badge: {
    color: "#fff",
    backgroundColor: "#009688",
    padding: "6px  0px",
    width: 180,
    paddingLeft: 8,
    textAlign: "left",
    borderRadius: 4,
  },
});

class ServicePageDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initData: [],
      serviceId: null,
      isLoading: false,
      alertOpen: false,
      alertMessage: "",
      alertType: "",
      serviceDetails: {},
      isShowDiscountDetails: true,
      isShowServiceDetails: true,
      isShowPageDetails: true,
      discountFields: [{ start: "", end: "", discount: "" }],
      isAddDiscount: false,
      isEditDiscount: false,
      discounts: [],
      discount: {},
      isShowShiftDetails: true,
      errors: {},
      isShowHtmlTag: false,
      formData: {
        about_text: "",
      },
    };
  }

  componentDidMount = async () => {
    const {
      match: { params },
    } = this.props;
    this.setState({ serviceId: params.serviceId, isLoading: true });

    try {
      const serviceResponse = await getServiceById(params.serviceId);

      this.setState({
        isLoading: false,
        serviceDetails: serviceResponse.data,
      });
    } catch (e) {}
  };

  setLoading = (value) => {
    this.setState({ isLoading: value });
  };

  updateHtmlTags = async (text) => {
    this.setState({ isLoading: true });
    const { serviceDetails } = this.state;
    let top = text.top_tag;
    let bottom = text.bottom_tag;

    const data = {
      top_tag: top,
      bottom_tag: bottom,
    };
    // console.log(data);
    try {
      const response = await updateHtmlTags(serviceDetails.id, data);
      const ser = {
        ...serviceDetails,
        top_tag: response.data.top_tag,
        bottom_tag: response.data.bottom_tag,
      };
      this.setState({
        serviceDetails: ser,
        isLoading: false,
        alertOpen: true,
        alertMessage: response.message,
        alertType: "success",
      });
    } catch (e) {
      this.setState({
        isLoading: false,
        alertOpen: true,
        alertMessage: "Something went wrong!",
        alertType: "error",
      });
    }
  };

  convertHtmlTags = (html) => {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };

  saveAboutText = async () => {
    console.log("saveAboutText ", this.state);
    this.setState({ isLoading: true });
    await updateAboutText({
      ...this.state.formData,
      id: this.state.serviceDetails.id,
    });

    this.setState({ isLoading: false });
  };

  uploadImages = async (images) => {
    // console.log("uploadImages", images);

    const {
      match: { params },
    } = this.props;

    this.setLoading(true);

    if (images.length > 0) {
      for (var i = 0; i < images.length; i++) {
        // set the payload
        const payload = {
          id: this.state.serviceDetails.id,
          location: "service",
          image: images[i].data_url,
        };

        await uploadImage(payload);
      }
    }

    const response = await getServiceById(params.serviceId);

    this.setState({
      isLoading: false,
      serviceDetails: response.data,
    });
  };

  deleteImageConfirm = async (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Confirm delete",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        // resolve();
        this.deleteImage(data);
      }
    });
  };

  deleteImage = async (data) => {
    this.setLoading(true);

    await deleteImage({
      ...data,
      id: this.state.serviceDetails.id,
    });

    const {
      match: { params },
    } = this.props;

    const response = await getServiceById(params.serviceId);

    this.setState({
      isLoading: false,
      serviceDetails: response.data,
    });
  };

  confirm = async (text) => {
    return Promise((resolve) => {
      Swal.fire({
        title: "Are you sure?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          resolve();
        }
      });
    });
  };

  render() {
    const { classes } = this.props;
    const {
      initData,
      isLoading,
      alertOpen,
      alertMessage,
      serviceDetails,
      alertType,
      isShowServiceDetails,
      isShowPageDetails,
      isShowDiscountDetails,
      isEditDiscount,
      isOpen,
      isAddDiscount,
      discounts,
      discount,
      isShowShiftDetails,
      errors,
      isShowHtmlTag,
    } = this.state;
    return (
      <RootElement>
        <div className={classes.serviceDetails}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() =>
              this.setState({
                isShowPageDetails: !this.state.isShowPageDetails,
              })
            }
          >
            <h3>{serviceDetails.name && serviceDetails.name + " - "}Page details</h3>
            {isShowPageDetails ? <ExpandMoreIcon /> : <ExpandLessIcon />}

            <button
              style={{ margin: "0px" }}
              onClick={() => this.saveAboutText()}
              className="btn btn-success"
            >
              Save
            </button>
          </div>

          <Divider style={{ margin: "4px 0px" }} />
          {isShowPageDetails && (
            <div className="" style={{ padding: "12px 0 0 12px" }}>
              
              <TextArea
                label="About Text"
                value={serviceDetails.about_text}
                getValue={(text) =>
                  this.setState({
                    ...this.state.formData,
                    formData: { about_text: text },
                  })
                }
              />

               <Divider style={{ margin: "50px 0px" }} />

              <div className="my-5">
                <div style={{ marginTop: 20 }}>
                  <h2>Slider Images</h2>
                </div>
                <Divider style={{ marginBottom: 20 }} />
                {serviceDetails && (
                  <div className="row">
                    {serviceDetails.images &&
                      serviceDetails.images.map((image, index) => (
                        <div key={index} className="col-4 image-item mb-1">
                          <img
                            src={`${process.env.REACT_APP_API}/storage/${image.path}`}
                          />

                          <div className="image-item__btn-wrapper">
                            <button
                              className="image-uploader-button text-danger"
                              onClick={() => this.deleteImageConfirm(image)}
                            >
                              <i class="bi bi-trash3"></i>
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>

              <div style={{ marginTop: 20 }}>
                <h5>Upload Slider Images</h5>
              </div>

              <div>
                <ImageUploader
                  serviceDetails={serviceDetails}
                  handleClick={(data) => this.uploadImages(data)}
                />
              </div>
            </div>
          )}
        </div>

        <div className={classes.serviceDetails}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() =>
              this.setState({ isShowHtmlTag: !this.state.isShowHtmlTag })
            }
          >
            <h3>Service top and bottom html tags</h3>
            {isShowHtmlTag ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </div>
          <Divider style={{ margin: "4px 0px" }} />
          {isShowHtmlTag && (
            <div className="row" style={{ padding: "12px 0 0 12px" }}>
              <div className="col-12">
                <ServiceHtmlTagForm
                  initialValues={serviceDetails}
                  errors={{}}
                  getFormValue={(value) => this.updateHtmlTags(value)}
                />
              </div>
              {/*<div>{this.convertHtmlTags(serviceDetails.bottom_tag)}</div>*/}
            </div>
          )}
        </div>

        {isOpen && (
          <CustomDialog
            closeDialog={() =>
              this.setState({
                isOpen: false,
                isAddDiscount: false,
                isEditDiscount: false,
              })
            }
            isNoteOpen={isOpen}
          >
            {isAddDiscount && (
              <DiscountForm
                initialValues={""}
                errors={errors}
                handleClose={() =>
                  this.setState({ isOpen: false, isAddDiscount: false })
                }
                getFormValue={(value) => this.saveDiscount(value)}
              />
            )}
            {isEditDiscount && (
              <DiscountForm
                initialValues={discount}
                errors={errors}
                handleClose={() =>
                  this.setState({ isOpen: false, isEditDiscount: false })
                }
                getFormValue={(value) => this.updateDiscount(value)}
              />
            )}
          </CustomDialog>
        )}
        {isLoading && <CustomLoader isNoteOpen={true} />}

        {alertOpen && (
          <AlertBar
            alertMessage={alertMessage}
            alertType={alertType}
            onClose={() => this.setState({ alertOpen: false })}
          />
        )}
      </RootElement>
    );
  }
}

export default withStyles(styles)(ServicePageDetails);
