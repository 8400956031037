import React, { Component } from "react";
import moment from "moment";
import "./style/login.css";
import {
  loginUser,
  sendResetEmail,
  confirmResetEmailCode,
  resetPassword,
} from "../services/AuthService";
import LoginComponent from "../components/Login/LoginComponent";
import CustomLoader from "../components/CustomLoader";
import AlertBar from "../components/core/Alert/AlertBar";

// Custom imports
import { APP_NAME } from "../store/constance/actionTypes";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      isLoading: false,
      alertOpen: false,
      alertMessage: "",
      alertType: "",
      passwordResetEmailSent: false,
      passwordResetCodeConfirmed: false,
      email: "",
    };
  }

  componentDidMount = async () => {
    document.title = `Login - ${APP_NAME}`;
  };

  loginSubmit = async (data) => {
    const currectTime = moment().format("YYYY-MM-DD");
    localStorage.setItem("auth_user_date", currectTime);

    this.setState({ isLoading: true, errors: {} });

    const user = {
      email: data.email,
      password: data.password,
    };

    try {
      const res = await loginUser(user);

      const userRoles = res.data.user.roles;

      const found = userRoles.filter(
        (role) =>
          role === "super_admin" || role === "manager" || role === "admin"
      );

      if (found.length > 0) {
        this.props.history.push("/dashboard");
      } else {
        this.props.history.push("/");
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.errors) {
        this.setState({
          isLoading: false,
          errors: e.response.data.errors,
        });
      } else {
        this.setState({
          isOpen: false,
          isAdd: false,
          isLoading: false,
          alertOpen: true,
          alertMessage: "Something went wrong",
          alertType: "error",
        });
      }
    }
  };

  sendPasswordResetEmail = async (data) => {
    try {
      this.setState({ isLoading: true, errors: [] });

      const res = await sendResetEmail({ ...data });
      console.log(`res`, res);

      this.setState({
        isLoading: false,
        email: data.email,
        passwordResetEmailSent: true,
        errors: [],
      });
    } catch (error) {
      console.log(`error`, error.response.data.data);
      this.setState({ isLoading: false, errors: error.response.data.data });
    }
  };

  validatePasswordResetCode = async (code) => {
    try {
      this.setState({ isLoading: true, errors: [] });

      const res = await confirmResetEmailCode({
        ...code,
        email: this.state.email,
      });
      console.log(`res`, res);

      this.setState({
        isLoading: false,
        passwordResetCodeConfirmed: true,
        errors: [],
      });
    } catch (error) {
      console.log(`error`, error.response.data.data);
      this.setState({ isLoading: false, errors: error.response.data.data });
    }
  };

  confirmPasswordReset = async (data) => {
    try {
      this.setState({ isLoading: true, errors: [] });

      const res = await resetPassword({ ...data, email: this.state.email });

      this.setState({
        isLoading: false,
        alertOpen: true,
        alertMessage: res.data,
        alertType: "success",
        errors: [],
      });
    } catch (error) {
      console.log(`error`, error);
      this.setState({ isLoading: false, errors: [] });
    }
  };

  handleAlertClose = async () => {
    this.setState({ alertOpen: false });
    window.location.reload();
  };

  render() {
    const {
      errors,
      isLoading,
      alertOpen,
      alertMessage,
      alertType,
      passwordResetEmailSent,
      passwordResetCodeConfirmed,
    } = this.state;
    return (
      <div
        className="global-container"
        style={{ height: "calc(100vh - 160px)", backgroundColor: "white" }}
      >
        {/*<LoginForm getFormValue={val=>this.loginSubmit(val)}/>*/}
        <LoginComponent
          errors={errors}
          getFormValue={(val) => this.loginSubmit(val)}
          passwordResetEmailSent={passwordResetEmailSent}
          passwordResetCodeConfirmed={passwordResetCodeConfirmed}
          sendPasswordResetEmail={this.sendPasswordResetEmail}
          validatePasswordResetCode={this.validatePasswordResetCode}
          confirmPasswordReset={this.confirmPasswordReset}
        />
        {isLoading && <CustomLoader isNoteOpen={true} />}

        {alertOpen && (
          <AlertBar
            alertMessage={alertMessage}
            alertType={alertType}
            onClose={this.handleAlertClose}
          />
        )}
      </div>
    );
  }
}

export default Login;
