import API from '../../utils/api';

export const fetchHoldingAmountsByType = async (type) => {
    try {
        let response = await API.get(`booking/holding-amounts/${type}`);
        return response.data;
    } catch (e) {
        throw e;
    }
};
