import {
	SHOW_MODAL,
	SAVE_ALL_BOOKING_DATA,
	SAVE_SERVICE_DATA,
	SAVE_GENERATED_SLOTS,
	SET_INVOICES
} from "../constance/actionTypes.js";

export const showModal = (data) => ({ type: SHOW_MODAL, payload: data });

export const saveAllBookingData = (data) => ({
	type: SAVE_ALL_BOOKING_DATA,
	payload: data,
});

export const saveServiceData = (data) => ({
	type: SAVE_SERVICE_DATA,
	payload: data,
});

export const saveGeneratedSlots = (data) => ({
	type: SAVE_GENERATED_SLOTS,
	payload: data,
});

export const saveInvoices = (data) => ({
	type: SET_INVOICES,
	payload: data,
});