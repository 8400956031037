import React from "react";
import TextField from "@material-ui/core/TextField";

export default function TextArea(props) {
    const handleChange = (event) => {
        props.getValue(event.target.value);
    };

    return (
        <TextField
            id="standard-multiline-static"
            label={props.label}
            multiline
            fullWidth
            InputLabelProps={{
                shrink: true,
            }}
            defaultValue={props.value ? props.value : ""}
            onChange={handleChange}
        />
    );
}
