import React, { Component } from "react";

// state
import { connect } from "react-redux";
import store from "../../store";
import { saveCustomer } from "../../store/actions/customerActions";

// services
import { fetchUsedCouponsById } from "../../services/private/CouponService";

// components
import RootElement from "./component/RootElement";
import CouponDetailsTable from "../../components/tables/CouponDetailsTable";
import CustomLoader from "../../components/CustomLoader";
import PaginationComponent from "../../components/PaginationComponent";
import AlertBar from "../../components/core/Alert/AlertBar";

// Custom imports
import { APP_NAME } from "../../store/constance/actionTypes";

class CouponDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponDetails: [],
      paginationDetail: {
        current_page: 1,
        first_page_url: "",
        from: 1,
        next_page_url: "",
        path: "",
        per_page: 5,
        prev_page_url: null,
        to: 5,
      },
      isLoading: false,
      tableHeader: [
        {
          align: "left",
          key: "id",
          label: "Code",
        },
        {
          align: "left",
          key: "coupon_title",
          label: "Title",
        },
        {
          align: "left",
          key: "date",
          label: "Offer (%)",
        },
        {
          align: "left",
          key: "user_id",
          label: "Used By",
        },
        {
          align: "left",
          key: "booking_details_id",
          label: "Used On",
        },
        {
          align: "left",
          key: "created_at",
          label: "Used at",
        },
      ],
      alertOpen: false,
      alertMessage: "",
      errors: {},
      alertType: "",
    };
  }

  componentDidMount = async () => {
    document.title = `Coupon Details - ${APP_NAME} Admin`;
    this._fetchCouponDetails(this.state.paginationDetail.current_page);
  };

  _fetchCouponDetails = async (page) => {
    try {
      this.setState({ isLoading: true });

      const {
        match: { params },
      } = this.props;

      const reqData = { page: page };

      const couponDetailsResponse = await fetchUsedCouponsById(
        params.couponCode,
        reqData
      );

      this.setState({
        couponDetails: couponDetailsResponse.data.data,
        paginationDetail: {
          ...couponDetailsResponse.data,
          data: [],
        },
        isLoading: false,
      });

      return Promise.resolve(200);
    } catch (e) {
      console.log(`error`, e);
      this.setState({
        isLoading: false,
      });

      return Promise.reject(400);
    }
  };

  reRenderApi = async () => {
    this._fetchCouponDetails(this.state.paginationDetail.current_page);
  };

  setLoadingState = async (value) => {
    this.setState({ isLoading: value });
  };

  goToDetails = async (userData) => {
    // const found = this.props.user.roles.find((role) => role === "super_admin");

    // if (found === undefined) {
    // this is a customer
    await store.dispatch(saveCustomer({ customer: userData }));
    // }

    this.props.history.push("/profile");
  };

  render() {
    const {
      couponDetails,
      tableHeader,
      isLoading,
      alertOpen,
      alertMessage,
      alertType,

      paginationDetail,
    } = this.state;

    return (
      <RootElement>
        <div>
          {couponDetails && (
            <CouponDetailsTable
              goToDetails={this.goToDetails}
              confirmCancel={(data) => this.confirmCancel(data)}
              header={tableHeader}
              couponDetails={couponDetails}
              reRenderApi={() => this.reRenderApi()}
              setLoadingState={(value) => this.setLoadingState(value)}
            >
              {paginationDetail && paginationDetail.links && (
                <PaginationComponent
                  data={paginationDetail}
                  setCurrentPage={(page) => this._fetchCouponDetails(page)}
                />
              )}
            </CouponDetailsTable>
          )}
        </div>

        {isLoading && <CustomLoader isNoteOpen={true} />}
        {alertOpen && (
          <AlertBar
            alertMessage={alertMessage}
            alertType={alertType}
            onClose={() => this.setState({ alertOpen: false })}
          />
        )}
      </RootElement>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;
  return { user };
};
export default connect(mapStateToProps, null)(CouponDetails);
