// token
export const SET_TOKEN = "SET_TOKEN";
export const REMOVE_TOKEN = "REMOVE_TOKEN";

// user
export const FETCH_USER = "FETCH_TOKEN";
export const REMOVE_USER = "REMOVE_USER";

// booking
export const ADD_SLOTS = "ADD_SLOTS";
export const REMOVE_SLOTS = "REMOVE_SLOTS";
export const CLEAR_SLOTS = "CLEAR_SLOTS";
export const INIT_LOAD_SLOTS = "INIT_LOAD_SLOTS";

//membership
export const ADD_MEMBERSHIP = "ADD_MEMBERSHIP";
export const REMOVE_MEMBERSHIP = "REMOVE_MEMBERSHIP";

//editBooking store the booking_detail_id
export const ADD_BOOKING_DETAILS = "ADD_BOOKING_DETAILS";
export const REMOVE_BOOKING_DETAILS = "REMOVE_BOOKING_DETAILS";

// This is not belongs to redux actions
export const APP_NAME = "Linen";

// App actions
export const SHOW_MODAL = "SHOW_MODAL";
export const SAVE_ALL_BOOKING_DATA = "SAVE_ALL_BOOKING_DATA";
export const SAVE_SERVICE_DATA = "SAVE_SERVICE_DATA";
export const SAVE_GENERATED_SLOTS = "SAVE_GENERATED_SLOTS";


// Coupon actions
export const UPDATE_COUPON = "UPDATE_COUPON";

// Coupon actions
export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const SET_CUSTOMER = "SET_CUSTOMER";

// Invoice actions
export const SET_INVOICES = "SET_INVOICES";
