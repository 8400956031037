import {
  SHOW_MODAL,
  SAVE_ALL_BOOKING_DATA,
  SAVE_SERVICE_DATA,
  SAVE_GENERATED_SLOTS,
  SET_INVOICES,
} from "../constance/actionTypes.js";

let initialState = {
  showModal: false,
  modalData: {
    bookingdetails: {
      id: "",
      photographer_name: "",
      shoot_type: "",
      payments: [
        {
          sub_total: 0,
        },
      ],
    },
    service: {
      name: "",
    },
    shift: {
      day: "",
      name: "",
      start_time: "",
      end_time: "",
      actual_price: 0,
      price: 0,
    },
  },
  allBookingData: {},
  serviceData: {},
  generatedSlots: [],
  invoices: [],
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      //   console.log(`show modal reducer => payload`, action.payload);
      return {
        ...initialState,
        showModal: action.payload.showModal,
        modalData: action.payload.modalData,
      };
    // I am saving the branch, slots, services data here bcz booking reducer is not desgin to work with other datas.
    // editing booking data will cost more time than this. Since we don't have time I decide to keep it here.
    // If you're working on this project and seeing this? Please move this to bookingReducer and make it work.
    case SAVE_ALL_BOOKING_DATA:
      return {
        ...state,
        allBookingData: { ...action.payload },
      };

    case SAVE_SERVICE_DATA:
      return {
        ...state,
        serviceData: { ...action.payload },
      };

    case SAVE_GENERATED_SLOTS:
      return {
        ...state,
        generatedSlots: action.payload,
      };

    case SET_INVOICES:
      return {
        ...state,
        invoices: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
