import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import BootstrapInputText from "../core/InputText/BootstrapInputText";

const useStyles = makeStyles((theme) => ({
    footer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        "&>:first-child": {
            marginRight: "12px",
        },
    },
}));
const PasswordResetConfirmCodeForm = (props) => {
    const { errors } = props;
    const classes = useStyles();

    const formik = useFormik({
        initialValues: {
            code: "",
        },
        validationSchema: Yup.object({
            code: Yup.string().required("Required"),
        }),
        onSubmit: (values) => {
            const { getFormValue } = props;
            if (getFormValue) {
                getFormValue(values);
            }
        },
    });

    return (
        <div
            style={{
                padding: "30px",
                paddingTop: "0",
                paddingLeft: "15px",
                marginTop: 30,
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="form-row">
                    <BootstrapInputText
                        id={"code"}
                        label={"Reset Code"}
                        placeholder={"Enter the reset Code"}
                        value={formik.values.code}
                        error={
                            Boolean(formik.errors.code) && formik.touched.code
                        }
                        errorMessage={formik.errors.code}
                        getValue={(value) =>
                            formik.setFieldValue("code", value)
                        }
                        onBlur={() => formik.setFieldTouched("code")}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        color: "red",
                        padding: "16px",
                    }}
                >
                    {errors.map((error, index) => {
                        return <p key={index}>{error}</p>;
                    })}
                </div>
                <div className={classes.footer}>
                    <button type={"submit"} className="btn btn-success">
                        Confirm
                    </button>
                </div>
            </form>
        </div>
    );
};
export default PasswordResetConfirmCodeForm;
