import React, { Component } from "react";
import PublicRootElement from "./admin/component/PublicRootElement";

// import CustomLoader from "../components/CustomLoader";
import Profile from "../components/Profile";

// Custom imports
import { APP_NAME } from "../store/constance/actionTypes";
import { fetchPoints } from "../services/UserService";
import {
  updateUser,
  grantPoints,
  fetchCoupons,
} from "../services/private/UserService";
import { fetchBookingDetails } from "../services/private/InvoiceService";
import { createInvoice } from "../services/private/BookingService";

import moment from "moment";

// redux
import { connect } from "react-redux";
import store from "../store";
import { saveCustomer } from "../store/actions/customerActions";
import { fetchUser } from "../store/actions/userAction";
import { saveInvoices } from "../store/actions/appActions";
import { fetchUserDetails } from "../services/UserService";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceDetails: [],
      isAdmin: false,
      isLoading: false,
      userLoading: false,
      pointsLoading: false,
      couponsLoading: false,
      invoiceLoading: false,
      // Loading: false;
      tableHeader: [
        {
          align: "left",
          key: "photographer_name",
          label: "Photographer name",
        },
        {
          align: "left",
          key: "shoot_type",
          label: "Shoot type",
        },
        {
          align: "left",
          key: "No of slots",
          label: "No of slots",
        },
      ],
      points: [],
      paginationDetail: {
        current_page: 1,
        first_page_url: "",
        from: 1,
        next_page_url: "",
        path: "",
        per_page: 5,
        prev_page_url: null,
        to: 5,
      },
      coupons: [],
      user: {},
      date: moment().startOf("month").format("YYYY-MM-DD"),
    };
  }

  componentDidMount = async () => {
    document.title = `${this.props.user.firstname} - ${APP_NAME}`;
    this.fetchUserPoints(this.state.paginationDetail.current_page);

    const roles = this.props.user.roles;

    if (roles.length > 0 && roles[0] === "super_admin") {
      this.setState({ isAdmin: true });
    }
  };

  fetchUserPoints = async (page) => {
    this.setState({ pointsLoading: true });

    const reqDate = { page: page };

    try {
      const _customer = this.props.customer;

      if (_customer.customer && Object.keys(_customer.customer).length > 0) {
        this.setState({ user: _customer.customer });

        let request = await fetchPoints(_customer.customer.id, reqDate);
        // update state
        if (request)
          this.setState({
            points: request.points.data,
            paginationDetail: {
              ...request.points,
              data: [],
            },
          });
      } else {
        this.setState({ user: this.props.user });

        fetchUserDetails().then((res) => {
          // update user state
          this.setState({ user: res });
          store.dispatch(fetchUser(res));
        });

        let request = await fetchPoints(this.props.user.id, reqDate);
        // update state
        if (request)
          this.setState({
            points: request.points.data,
            paginationDetail: {
              ...request.points,
              data: [],
            },
          });
      }

      this.setState({ pointsLoading: false });
    } catch (err) {
      this.setState({ pointsLoading: false });
    }
  };

  updateCustomerDetails = async (payload) => {
    console.log(`updateCustomerDetails`, payload);

    this.setState({ userLoading: true });

    const response = await updateUser({
      ...payload,
      id: this.state.user.id,
    });

    // console.log(`response`, response);

    await store.dispatch(saveCustomer({ customer: response.data }));

    this.setState({ userLoading: false, user: response.data });
  };

  updateUserPoints = async (formData) => {
    console.log(`updateUserPoints`, formData);

    this.setState({ pointsLoading: true });

    await grantPoints({
      user_id: this.state.user.id,
      ...formData,
      granted_by: this.props.user.id,
    });

    await this.fetchUserPoints(this.state.paginationDetail.current_page);

    setTimeout(() => {
      this.setState({ pointsLoading: false });
    }, 2000);
  };

  fetchUserCoupons = async (id) => {
    this.setState({ couponsLoading: true });
    try {
      // console.log(`fetchUserCoupons`, id);
      const response = await fetchCoupons(id);

      this.setState({ coupons: response.data, couponsLoading: false });
    } catch (error) {
      console.log(`error`, error);
    }
  };

  _fetchBookingDetails = async () => {
    try {
      const { user } = this.props;

      var reqDate = {
        user_id: user.id,
        forInvoice: true,
        page: 1,
      };

      const _customer = this.props.customer;

      if (_customer.customer && Object.keys(_customer.customer).length > 0) {
        reqDate = { ...reqDate, user_id: _customer.customer.id };
      }

      const bookingDetailsResponse = await fetchBookingDetails(reqDate);

      await store.dispatch(saveInvoices(bookingDetailsResponse.data.data));

      this.setState({
        invoiceLoading: false,
      });

      return Promise.resolve(200);
    } catch (e) {
      console.log(`error`, e);
      this.setState({
        isLoading: false,
      });

      return Promise.reject(400);
    }
  };

  createNewInvoice = async (payload) => {
    this.setState({ invoiceLoading: true });

    const { user } = this.props;

    var requestParams = {
      user_id: user.id,
    };

    const _customer = this.props.customer;

    if (_customer.customer && Object.keys(_customer.customer).length > 0) {
      requestParams = { ...requestParams, user_id: _customer.customer.id };
    }

    await createInvoice(requestParams.user_id, payload);

    this._fetchBookingDetails();

    this.setState({ invoiceLoading: false });
  };

  render() {
    const {
      isAdmin,
      points,
      user,
      userLoading,
      pointsLoading,
      coupons,
      couponsLoading,
      date,
      paginationDetail,
      invoiceLoading,
    } = this.state;

    const { membership } = this.props;

    return (
      <PublicRootElement>
        <Profile
          membership={membership}
          user={user}
          points={points}
          isAdmin={isAdmin}
          updateCustomerDetails={this.updateCustomerDetails}
          userLoading={userLoading}
          date={date}
          pointsLoading={pointsLoading}
          fetchUserPoints={this.fetchUserPoints}
          paginationDetail={paginationDetail}
          updateUserPoints={this.updateUserPoints}
          coupons={coupons}
          couponsLoading={couponsLoading}
          fetchUserCoupons={this.fetchUserCoupons}
          goToDetails={(id) => this.props.history.push("/invoices/" + id)}
          invoiceLoading={invoiceLoading}
          createNewInvoice={this.createNewInvoice}
        />

        {/*{isLoading && <CustomLoader isNoteOpen={true} />}*/}
      </PublicRootElement>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, membership, customer, app } = state;
  return { user, membership, customer, app };
};
export default connect(mapStateToProps, null)(UserProfile);
