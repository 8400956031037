import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import InputText from "../core/InputText/InputText";
import InputSelect from "../core/InputSelect/InputSelect";

const useStyles = makeStyles((theme) => ({
    footer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        "&>:first-child": {
            marginRight: "12px",
        },
    },
}));

const numPattern = /^[0-9]*$/;
const expiryPatter = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;

const CreditCardForm = (props) => {
    const { initialValues, errors } = props;
    const classes = useStyles();

    const formik = useFormik({
        initialValues: {
            card_number: initialValues.card_number
                ? initialValues.card_number
                : "",
            name_on_card: initialValues.name_on_card
                ? initialValues.name_on_card
                : "",
            expiry: initialValues.expiry ? initialValues.expiry : "",
            cvv_number: initialValues.cvv_number
                ? initialValues.cvv_number
                : "",
        },
        validationSchema: Yup.object({
            card_number: Yup.string()
                .matches(numPattern, "Card number should be number.")
                .min(16, "Card number should be at least 16 digits.")
                .required("Required"),
            name_on_card: Yup.string().required("Required"),
            expiry: Yup.string()
                .matches(expiryPatter, "Please enter expire date in MM/YY format.")
                .required("Required"),
            cvv_number: Yup.string()
                .matches(numPattern, "CVV should be number.")
                .min(3, "CVV should be 3 digits.")
                .max(3, "CVV cannot be more than 3 digits.")
                .required("Required"),
        }),
        onSubmit: (values) => {
            const { getFormValue } = props;
            if (getFormValue) {
                getFormValue(values);
            }
        },
    });

    return (
        <div style={{ padding: "24px", paddingTop: "0" }}>
            <form onSubmit={formik.handleSubmit} style={{ marginTop: "0px" }}>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <InputText
                            id={"card-number"}
                            label={"Card number"}
                            placeholder={"Please enter your card number"}
                            value={formik.values.card_number}
                            error={
                                Boolean(formik.errors.card_number) &&
                                formik.touched.card_number
                            }
                            errorMessage={formik.errors.card_number}
                            getValue={(value) =>
                                formik.setFieldValue("card_number", value)
                            }
                            onBlur={() => formik.setFieldTouched("card_number")}
                        />
                    </div>

                    <div className="form-group col-md-6">
                        <InputText
                            id={"name"}
                            label={"Name on Card"}
                            placeholder={"Name on Card"}
                            value={formik.values.name_on_card}
                            error={
                                Boolean(formik.errors.name_on_card) &&
                                formik.touched.name_on_card
                            }
                            errorMessage={formik.errors.name_on_card}
                            getValue={(value) =>
                                formik.setFieldValue("name_on_card", value)
                            }
                            onBlur={() =>
                                formik.setFieldTouched("name_on_card")
                            }
                        />
                    </div>

                    <div className="form-group col-md-6">
                        <InputText
                            id={"name"}
                            label={"Expiry"}
                            placeholder={"MM/YY"}
                            value={formik.values.expiry}
                            error={
                                Boolean(formik.errors.expiry) &&
                                formik.touched.expiry
                            }
                            errorMessage={formik.errors.expiry}
                            getValue={(value) =>
                                formik.setFieldValue("expiry", value)
                            }
                            onBlur={() => formik.setFieldTouched("expiry")}
                        />
                    </div>

                    <div className="form-group col-md-6">
                        <InputText
                            id={"name"}
                            label={"CVV"}
                            placeholder={"CVV"}
                            value={formik.values.cvv_number}
                            error={
                                Boolean(formik.errors.cvv_number) &&
                                formik.touched.cvv_number
                            }
                            errorMessage={formik.errors.cvv_number}
                            getValue={(value) =>
                                formik.setFieldValue("cvv_number", value)
                            }
                            onBlur={() => formik.setFieldTouched("cvv_number")}
                        />
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        color: "red",
                        padding: "16px",
                    }}
                >
                    {Object.keys(errors).map((key) => {
                        return (
                            <div>
                                {errors[key].map((error) => {
                                    return <p>{error}</p>;
                                })}
                            </div>
                        );
                    })}
                </div>
                <div className={classes.footer}>
                    <button
                        onClick={props.handleClose && props.handleClose}
                        className="btn btn-danger"
                    >
                        Cancel
                    </button>
                    <button type={"submit"} className="btn btn-success">
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};
export default CreditCardForm;
