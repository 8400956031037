import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Makeup room Summary",
    },
  },
};

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  /*  "August",
  "September",
  "October",
  "November",
  "December",*/
];

/*export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => 250),
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: labels.map(() => 550),
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};
*/

/**
 * @params payload is actually bookingDetails
 **/
function MakeupRoomBarChart({ payload }) {
  const data = {
    labels,
    datasets: [
      {
        label: "Makeup Room1",
        data: labels.map((label) => {
          if (payload[label] !== undefined) {
            // console.log(`aaaa`,payload[label]);
            return payload[label]["Makeup Room1"];
          }
        }),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Other",
        data: labels.map((label) => 1),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return <Bar options={options} data={data} />;
}

export default MakeupRoomBarChart;
