import React from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import { find, isEmpty, isEqual } from "lodash";
import "../../theme/css/invoiceTable.css";
import RolePermission from "../RolePermission/RolePermission";

// Custom imports
import {
  cancelBookingByAdmin,
  cancelSlots,
} from "../../services/private/InvoiceService";

function TableHead({ header }) {
  return (
    <thead>
      <tr>
        {header.map((header, index) => {
          return (
            <th
              style={{ textAlign: header.align, border: "none" }}
              scope="col"
              key={index}
            >
              {header.label}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

// I wrote this function because I cannot use await in Swal .then() block
async function clearSlotsRequest(id, payload) {
  try {
    // console.log(`payload => `, payload);
    await cancelSlots(id, payload);

    window.location.reload();
    // console.log(`res => `, request);
  } catch (error) {
    console.log(`error => `, error.response.data);
    Swal.fire("Error", "Something went wrong", "error");
  }
}

async function handleChangeBooking(bookingData, user, handleState, membership) {
  let { member_ship } = user;

  let paymentDetails = bookingData.payments.find(
    (payment) => payment.payment_status === "SUCCESS"
  );

  let holdAmount = 0;

  // console.log(`bookingData => `, bookingData);
  // console.log(`paymentDetails => `, paymentDetails);
  // return;

  if (paymentDetails) {
    holdAmount = (paymentDetails.grant_total * membership.exchange) / 100;
  }

  Swal.fire({
    title: `Are you sure? You want to change the booking. You won't be able to revert this!`,
    text: `$${holdAmount.toFixed(
      2
    )} will be hold and deduct after you change the booking.`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes",
  }).then((result) => {
    if (result.isConfirmed) {
      // Swal.fire("Deleted!", "Your file has been deleted.", "success");

      handleState({ isLoading: true });

      const reqData = {
        sub_total: find(bookingData.payments, { payment_status: "SUCCESS" })
          .sub_total,
        discount: find(bookingData.payments, { payment_status: "SUCCESS" })
          .discount,
        grant_total: find(bookingData.payments, { payment_status: "SUCCESS" })
          .grant_total,
        booked_by: `cancel by ${user.name}`,
        invoice_id: bookingData.id,
        user_id: bookingData.user_id,
        member_ship_exchange_value: membership.exchange,
        holdAmount,
      };

      console.log(`reqData => `, reqData);

      clearSlotsRequest(bookingData.id, reqData);
    }
  });
}

const InvoiceTable = ({
  header,
  bookingDetails,
  editBooking,
  goToDetails,
  requestToCancel,
  confirmCancel,
  user,
  reRenderApi,
  setLoadingState,
  handleState,
  direct,
  membership,
  children,
}) => {
  async function handleCancelBooking(bookingData, user) {
    // let { member_ship } = user;

    // console.log(`bookingData => `, bookingData);
    // return;

    const inputOptions = new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          MOVE_TO_POINTS: "Points",
          REFUND: "Refund",
          CUSTOM_REFUND: "Custom",
          JUST_CANCEL: "Cancel",
        });
      }, 100);
    });

    const { value: type } = await Swal.fire({
      title: `Are you sure? You want to cancel`,
      text: "Please select the cancellation type",
      input: "radio",
      inputOptions: inputOptions,
      inputValidator: (value) => {
        if (!value) {
          return "You need to choose something!";
        }
      },
      showCloseButton: true,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: `Cancel`,
      confirmButtonText: `Confirm`,
      confirmButtonColor: "#5cb85c",
    });

    setLoadingState(true);

    try {
      if (type === "CUSTOM_REFUND") {
        // console.log(`type => `, type);
        const { value: amount } = await Swal.fire({
          title: "Enter refund amount",
          input: "number",
          inputLabel: "Refund Amount",
          inputPlaceholder: "Enter your desired refund amount",
          inputValidator: (value) => {
            if (!value) {
              return "You need to enter an amount!";
            }
          },
        });

        if (!find(bookingData.payments, { payment_status: "SUCCESS" })) {
          setLoadingState(false);
          Swal.fire("Custom cancel type is only valid for paid bookings");
          return;
        }

        if (amount) {
          const requiredData = {
            sub_total: find(bookingData.payments, { payment_status: "SUCCESS" })
              .sub_total,
            discount: find(bookingData.payments, { payment_status: "SUCCESS" })
              .discount,
            grant_total: find(bookingData.payments, {
              payment_status: "SUCCESS",
            }).grant_total,
            booked_by: `cancel by ${user.name}`,
            invoice_id: bookingData.id,
            user_id: bookingData.user_id,
            member_ship_exchange_value: membership.exchange,
            holdAmount: parseFloat(amount),
          };

          let formData = {
            id: "Inv-0001",
            type: type,
            data: { ...requiredData },
          };

          await cancelBookingByAdmin(formData);
          await reRenderApi();

          setLoadingState(false);
        }
      } else if (type === "MOVE_TO_POINTS" || type === "REFUND") {
        await requestToCancel(bookingData, type);
        await reRenderApi();

        setLoadingState(false);
      } else if (type === "JUST_CANCEL") {
        let formData = {
          id: "Inv-0001",
          type: type,
          data: { invoice_id: bookingData.id },
        };

        await cancelBookingByAdmin(formData);
        await reRenderApi();

        setLoadingState(false);
      }
    } catch (error) {
      setLoadingState(false);
      console.log(`Error => `, error);
    }
  }

  console.log(`bookingDetails`,bookingDetails);

  return (
    <div>
      <table className="table">
        <TableHead header={header} />
        <tbody>
          {bookingDetails.map((bookingDetail, index) => {
            return (
              <tr key={index}>
                <td style={{ textAlign: "left" }}>{bookingDetail.id}</td>
                <td style={{ textAlign: "left" }}>
                  {moment(bookingDetail.updated_at).format("YYYY-MM-DD")}
                </td>
                <td style={{ textAlign: "left" }}>
                  {bookingDetail.photographer_name}
                </td>
                <td style={{ textAlign: "left" }}>
                  {bookingDetail.shoot_type}
                </td>
                <td style={{ textAlign: "left" }}>
                  {bookingDetail.slots_details.length}
                </td>
                <td style={{ textAlign: "left" }}>
                  {!isEmpty(bookingDetail.cancel_type)
                    ? bookingDetail.status === "CANCELLING"
                      ? "Request to cancel"
                      : bookingDetail.status
                    : isEmpty(
                        find(bookingDetail.payments, {
                          payment_status: "SUCCESS",
                        })
                      )
                    ? "UNPAID"
                    : "PAID"}
                </td>
                <td
                  className="td-actions"
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <button
                    style={{ marginTop: 0 }}
                    onClick={() => {
                      goToDetails && goToDetails(bookingDetail);
                    }}
                    type="button"
                    rel="tooltip"
                    className="btn btn-info btn-link btn-just-icon btn-sm"
                    data-original-title=""
                    title="Invoice"
                  >
                    <i className="material-icons">description</i>
                  </button>
                  <RolePermission roles={["super_admin"]}>
                    <button
                      style={{ marginTop: 0 }}
                      disabled={!isEqual(bookingDetail.status, "CANCELLING")}
                      onClick={() => {
                        confirmCancel && confirmCancel(bookingDetail);
                      }}
                      type="button"
                      rel="tooltip"
                      className="btn btn-danger btn-link btn-just-icon btn-sm"
                      data-original-title=""
                      title="Approve Cancel Booking"
                    >
                      <i className="material-icons">check</i>
                    </button>
                    <button
                      style={{ marginTop: 0 }}
                      onClick={() => handleCancelBooking(bookingDetail, user)}
                      type="button"
                      rel="tooltip"
                      className="btn btn-danger btn-link btn-just-icon btn-sm"
                      data-original-title=""
                      title="Cancel Booking"
                      disabled={bookingDetail.status === "CANCELLED"}
                    >
                      <i className="material-icons">block</i>
                    </button>
                  </RolePermission>

                  {direct && (
                    <>
                      <button
                        style={{ marginTop: 0 }}
                        disabled={
                          !isEmpty(
                            find(bookingDetail.payments, {
                              payment_status: "SUCCESS",
                            })
                          )
                        }
                        onClick={() => {
                          editBooking && editBooking(bookingDetail);
                        }}
                        type="button"
                        rel="tooltip"
                        className="btn btn-success btn-link btn-just-icon btn-sm"
                        data-original-title=""
                        title="Edit"
                      >
                        <i className="material-icons">edit</i>
                      </button>
                      <button
                        style={{ marginTop: 0 }}
                        disabled={
                          !bookingDetail.modifyBooking ||
                          isEmpty(
                            find(bookingDetail.payments, {
                              payment_status: "SUCCESS",
                            })
                          ) ||
                          !isEmpty(find(bookingDetail.cancel_type))
                        }
                        onClick={() => {
                          confirmCancel && confirmCancel(bookingDetail);
                        }}
                        type="button"
                        rel="tooltip"
                        className="btn btn-danger btn-link btn-just-icon btn-sm"
                        data-original-title=""
                        title="Cancel Booking"
                      >
                        <i className="material-icons">close</i>
                      </button>
                     {/* <button
                        style={{ marginTop: 0 }}
                        onClick={() =>
                          handleChangeBooking(
                            bookingDetail,
                            user,
                            handleState,
                            membership
                          )
                        }
                        type="button"
                        rel="tooltip"
                        className="btn btn-danger btn-link btn-just-icon btn-sm"
                        data-original-title=""
                        title="Change Booking"
                        disabled={
                          !bookingDetail.modifyBooking ||
                          isEmpty(
                            find(bookingDetail.payments, {
                              payment_status: "SUCCESS",
                            })
                          ) ||
                          !isEmpty(find(bookingDetail.cancel_type))
                            ? true
                            : false
                        }
                      >
                        <i className="material-icons">autorenew</i>
                      </button>*/}
                    </>
                  )}

                  {/*{direct && (
                  <RolePermission roles={["user"]}>
                    <button
                      style={{ marginTop: 0 }}
                      disabled={
                        !isEmpty(
                          find(bookingDetail.payments, {
                            payment_status: "SUCCESS",
                          })
                        )
                      }
                      onClick={() => {
                        editBooking && editBooking(bookingDetail);
                      }}
                      type="button"
                      rel="tooltip"
                      className="btn btn-success btn-link btn-just-icon btn-sm"
                      data-original-title=""
                      title="Edit"
                    >
                      <i className="material-icons">edit</i>
                    </button>
                    <button
                      style={{ marginTop: 0 }}
                      disabled={
                        !bookingDetail.modifyBooking ||
                        isEmpty(
                          find(bookingDetail.payments, {
                            payment_status: "SUCCESS",
                          })
                        ) ||
                        !isEmpty(find(bookingDetail.cancel_type))
                      }
                      onClick={() => {
                        requestToCancel && requestToCancel(bookingDetail);
                      }}
                      type="button"
                      rel="tooltip"
                      className="btn btn-danger btn-link btn-just-icon btn-sm"
                      data-original-title=""
                      title="Cancel Booking"
                    >
                      <i className="material-icons">close</i>
                    </button>
                    <button
                      style={{ marginTop: 0 }}
                      onClick={() =>
                        handleChangeBooking(bookingDetail, user, handleState)
                      }
                      type="button"
                      rel="tooltip"
                      className="btn btn-danger btn-link btn-just-icon btn-sm"
                      data-original-title=""
                      title="Change Booking"
                      disabled={
                        !bookingDetail.modifyBooking ||
                        isEmpty(
                          find(bookingDetail.payments, {
                            payment_status: "SUCCESS",
                          })
                        ) ||
                        !isEmpty(find(bookingDetail.cancel_type))
                          ? true
                          : false
                      }
                    >
                      <i className="material-icons">autorenew</i>
                    </button>
                  </RolePermission>
                )}*/}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="d-flex justify-content-center my-3">{children}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { user, membership } = state;
  return { user, membership };
};

export default connect(mapStateToProps, null)(InvoiceTable);
