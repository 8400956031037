import React, { Component } from "react";
import { connect } from "react-redux";

// mui
import Skeleton from "@material-ui/lab/Skeleton";

import Swal from "sweetalert2";
import { chain } from "lodash";
import store from "../../store";
import moment from "moment";
// import CustomLoader from "../CustomLoader";
import AlertBar from "../core/Alert/AlertBar";
// import DateNavigator from "../core/dateNavigator/DateNavigator";
import InvoiceOnlyTable from "../tables/InvoiceOnlyTable";
import {
    fetchBookingDetails,
    requestToCancel,
} from "../../services/private/InvoiceService";
import { clearSlots, initLoadSlots } from "../../store/actions/bookingAction";
import {
    addBookingDetails,
    removeBookingDetails,
} from "../../store/actions/bookingDetailsAction";
import { saveInvoices } from "../../store/actions/appActions";
import { withRouter } from "react-router";

import PaginationComponent from "../PaginationComponent";

class BookedInvoiceHistory extends Component {
    constructor(props) {
        super(props);
        console.log(`props`, props);
        this.state = {
            bookingDetails: [],
            paginationDetail: {
                current_page: 1,
                first_page_url: "",
                from: 1,
                next_page_url: "",
                path: "",
                per_page: 5,
                prev_page_url: null,
                to: 5,
            },
            isLoading: false,
            tableHeader: [
                {
                    align: "left",
                    key: "date",
                    label: "Invoice ID",
                },
                {
                    align: "left",
                    key: "date",
                    label: "Booked date",
                },
                /*{
                    align: "left",
                    key: "photographer_name",
                    label: "Photographer name",
                },*/
                {
                    align: "left",
                    key: "shoot_type",
                    label: "Shoot type",
                },
                /*{
                    align: "left",
                    key: "No of slots",
                    label: "No of slots",
                },*/
                {
                    align: "left",
                    key: "status",
                    label: "Status",
                },
                {
                    align: "left",
                    key: "sub_total",
                    label: "Sub total",
                },
                {
                    align: "left",
                    key: "grant_total",
                    label: "Grand total",
                },

                {
                    align: "center",
                    key: "action",
                    label: "Action",
                },
            ],
            alertOpen: false,
            alertMessage: "",
            errors: {},
            alertType: "",
            date: moment(),
        };
    }

    componentDidMount = async () => {
        this.setState({ bookingDetails: this.props.app.invoices });
        this._fetchBookingDetails(this.state.paginationDetail.current_page);
    };

    _fetchBookingDetails = async (page) => {
        try {
            if (this.props.app.invoices.length <= 0) {
                this.setState({ isLoading: true });
            }

            const { user } = this.props;

            var reqDate = {
                user_id: user.id,
                forInvoice: true,
                page: page,
            };

            const _customer = this.props.customer;

            if (
                _customer.customer &&
                Object.keys(_customer.customer).length > 0
            ) {
                reqDate = { ...reqDate, user_id: _customer.customer.id };
            }

            const bookingDetailsResponse = await fetchBookingDetails(reqDate);
            await store.dispatch(
                saveInvoices(bookingDetailsResponse.data.data)
            );

            this.setState({
                bookingDetails: bookingDetailsResponse.data.data,
                paginationDetail: {
                    ...bookingDetailsResponse.data,
                    data: [],
                },
                isLoading: false,
            });

            return Promise.resolve(200);
        } catch (e) {
            console.log(`error`, e);
            this.setState({
                isLoading: false,
            });

            return Promise.reject(400);
        }
    };

    reRenderApi = async () => {
        this._fetchBookingDetails(this.state.paginationDetail.current_page);
    };

    requestToCancel = async (data) => {
        const inputOptions = new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    MOVE_TO_POINTS: "Move to points",
                    REFUND: "Refund",
                });
            }, 100);
        });

        const { value: type } = await Swal.fire({
            title: `Are you sure? You want to cancel`,
            text: "Please select the cancel option",
            input: "radio",
            inputOptions: inputOptions,
            inputValidator: (value) => {
                if (!value) {
                    return "You need to choose something!";
                }
            },
            showCloseButton: true,
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: `Cancel`,
            confirmButtonText: `Confirm`,
            confirmButtonColor: "#5cb85c",
        });
        if (type) {
            this.setState({ isLoading: true });

            try {
                const response = await requestToCancel(data.id, {
                    cancel_type: type,
                });
                this.reRenderApi().then((r) =>
                    this.setState({
                        isLoading: false,
                        alertOpen: true,
                        alertMessage: response.message,
                        alertType: "success",
                    })
                );
            } catch (e) {
                if (e.response && e.response.data && e.response.data.errors) {
                    this.setState({
                        isLoading: false,
                        errors: e.response.data.errors,
                        alertOpen: true,
                        alertMessage: e.response.data.errors.message
                            ? e.response.data.errors.message
                            : "Something went wrong",
                        alertType: "error",
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        alertOpen: true,
                        alertMessage: "Something went wrong",
                        alertType: "error",
                    });
                }
            }
        }
    };

    editBooking = async (data) => {
        const groupData = chain(data.slots_details)
            .groupBy("service_id")
            .map((value, key) => ({
                service_id: parseInt(key),
                slots: value.map((item) => ({
                    price: item.price,
                    shift_id: item.shift_id,
                    slot: item.slot,
                })),
            }))
            .value();
        await store.dispatch(clearSlots([]));
        await store.dispatch(removeBookingDetails(null));
        await store.dispatch(initLoadSlots(groupData));
        await store.dispatch(addBookingDetails(data));
        this.props.history.push("/booking/" + groupData[0].service_id);
    };
    handleChangeDate = async (value) => {
        const { user } = this.props;
        this.setState({ isLoading: true });
        try {
            const reqDate = {
                user_id: user.id,
                start_date: moment(value).format("YYYY-MM-DD"),
                end_date: moment(value).add(1, "weeks").format("YYYY-MM-DD"),
            };
            const bookingDetailsResponse = await fetchBookingDetails(reqDate);

            this.setState({
                bookingDetails: bookingDetailsResponse.data,
                date: value,
                isLoading: false,
            });
        } catch (e) {
            this.setState({
                isLoading: false,
            });
        }
    };

    render() {
        const {
            bookingDetails,
            tableHeader,
            isLoading,
            alertOpen,
            alertMessage,
            alertType,
            paginationDetail,
        } = this.state;
        return (
            <>
                <div className="container">
                    {isLoading ? (
                        <div>
                            <Skeleton
                                variant="rect"
                                width={300}
                                height={35}
                                style={{ marginBottom: 5 }}
                            />
                            <Skeleton
                                variant="rect"
                                height={50}
                                style={{ marginBottom: 5 }}
                            />
                            <Skeleton
                                variant="rect"
                                height={150}
                                style={{ marginBottom: 5 }}
                            />
                        </div>
                    ) : (
                        <>
                            {/*<DateNavigator
                                disablePast={false}
                                disableFuture={false}
                                type={"WEEK"}
                                isShowDate={true}
                                date={date}
                                onChangeDate={(date) =>
                                    this.handleChangeDate(date)
                                }
                            />*/}

                            {bookingDetails && (
                                <InvoiceOnlyTable
                                    goToDetails={(data) =>
                                        this.props.history.push(
                                            "/invoices/" + data.id
                                        )
                                    }
                                    requestToCancel={(data) =>
                                        this.requestToCancel(data)
                                    }
                                    editBooking={(data) =>
                                        this.editBooking(data)
                                    }
                                    header={tableHeader}
                                    bookingDetails={bookingDetails}
                                    direct={false}
                                >
                                    {paginationDetail &&
                                        paginationDetail.links && (
                                            <PaginationComponent
                                                data={paginationDetail}
                                                setCurrentPage={(page) =>
                                                    this._fetchBookingDetails(
                                                        page
                                                    )
                                                }
                                            />
                                        )}
                                </InvoiceOnlyTable>
                            )}
                        </>
                    )}
                </div>

                {/*{isLoading && <CustomLoader isNoteOpen={true} />}*/}
                {alertOpen && (
                    <AlertBar
                        alertMessage={alertMessage}
                        alertType={alertType}
                        onClose={() => this.setState({ alertOpen: false })}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { user, customer, app } = state;
    return { user, customer, app };
};
BookedInvoiceHistory = withRouter(BookedInvoiceHistory);
export default connect(mapStateToProps, null)(BookedInvoiceHistory);
