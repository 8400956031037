import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { get } from "lodash";

import { useFormik } from "formik";
import * as Yup from "yup";

import BookedHistory from "./bookedHIstory/BookedHistory";
import BookedInvoiceHistory from "./bookedHIstory/BookedInvoiceHistory";
import StarImg from "../assets/img/linen_and_love_studio_appointed_date.png";
import CalendarImg from "../assets/img/linen_and_love_studio_points_icon.png";
import CouponImg from "../assets/img/coupon.png";
import InvoiceImg from "../assets/img/invoice.png";

// mui
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";

// loaders
// import UserProfileLoader from "./SkeletonLoaders/UserProfileLoader";
import CustomDialog from "./core/CustomDialog";
import DateNavigator from "./core/dateNavigator/DateNavigator";
import GrantPointsForm from "./forms/GrantPointsForm";
import CreateInvoiceForm from "./forms/CreateInvoiceForm";
import PaginationComponent from "./PaginationComponent";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    padding: 0,
}));

export default function Profile({
    membership,
    user,
    points,
    isAdmin,
    updateCustomerDetails,
    userLoading,
    pointsLoading,
    updateUserPoints,
    coupons,
    couponsLoading,
    fetchUserCoupons,
    goToDetails,
    fetchUserPoints,
    date,
    paginationDetail,
    invoiceLoading,
    createNewInvoice,
}) {
    const bookingTableRef = useRef();

    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(false);
    const [showForm, setShowForm] = useState("");

    const [clicked, setClicked] = useState(false);
    const [editing, setEditing] = useState(false);
    const [showTable, setShowTable] = useState("");

    const _handleClick = (value, userId) => {
        if (bookingTableRef && bookingTableRef.current) {
            bookingTableRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }

        if (value === "coupons") {
            setShowTable("coupons");
            fetchUserCoupons(userId);
            return;
        } else if (value === "invoices") {
            setShowTable("invoices");
            return;
        } else {
            setShowTable("");
        }

        setClicked(value);
    };

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            firstname: Yup.string().required("Firstname required."),
            lastname: Yup.string().required("Lastname required."),
            phone: Yup.string().required("Phone is required."),
            email: Yup.string()
                .email("Please enter a valid email.")
                .required("Email address is required."),
        }),
        onSubmit: (values) => {
            setEditing(false);
            console.log(`onSubmit`, values);
            updateCustomerDetails(values);
        },
    });

    const getInputValue = (event) => {
        formik.setFieldValue(event.target.name, event.target.value);
    };

    useEffect(() => {
        if (user && formik.values.firstname != "") {
            formik.setFieldValue("firstname", user.firstname);
            formik.setFieldValue("lastname", user.lastname);
            formik.setFieldValue("email", user.email);
            formik.setFieldValue("phone", user.phone);
        }
    }, [user]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="dashboard">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                {userLoading ? (
                                    <div>
                                        <Skeleton variant="text" />
                                        <Skeleton
                                            variant="circle"
                                            width={80}
                                            height={80}
                                            style={{ margin: "10px 0px" }}
                                        />
                                        <Skeleton variant="rect" height={200} />
                                    </div>
                                ) : (
                                    <div className="card_wrapper profile_details">
                                        <div className="row">
                                            <div className="col-md-12 d-flex justify-content-center align-items-center text-center mb-3">
                                                <div className="vm_bg_gray w-100 d-flex rounded align-items-baseline">
                                                    <div className="vm_bg_green p-2 rounded-top rounded-left mr-2">
                                                        <i className="fa fa-star text-white"></i>
                                                    </div>
                                                    <p className="text-white">
                                                        {/* {get(user, "name", "")}*/}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-12 d-flex justify-content-center">
                                                <div className="circle_1 rounded-circle border p-2">
                                                    <div className="vm_bg_gray rounded-circle circle_2 text-center text-white p-4">
                                                        <h1 className="pt-3 px-3">
                                                            <b>
                                                                {get(
                                                                    user,
                                                                    "firstname",
                                                                    ""
                                                                )
                                                                    .substring(
                                                                        0,
                                                                        1
                                                                    )
                                                                    .toUpperCase()}

                                                                {get(
                                                                    user,
                                                                    "lastname",
                                                                    ""
                                                                )
                                                                    .substring(
                                                                        0,
                                                                        1
                                                                    )
                                                                    .toUpperCase()}
                                                            </b>
                                                        </h1>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<div className="col-md-12 pt-3 d-flex justify-content-center">
                                        <h4 className="text-uppercase">
                                            <b>
                                                <i className="fa fa-user"></i>
                                                {get(user, "lastname", "")}
                                            </b>
                                        </h4>
                                    </div>*/}
                                            <hr className="w-100 mt-3" />
                                            <div className="col-md-12">
                                                {/* <p className="text-center">
                                            {user.email}
                                        </p>*/}

                                                <div className="text-right mb-3">
                                                    <button
                                                        type="button"
                                                        className="user-profile-edit"
                                                    >
                                                        <EditIcon
                                                            onClick={() =>
                                                                setEditing(
                                                                    !editing
                                                                )
                                                            }
                                                            color="action"
                                                            style={{
                                                                cursor: "pointer",
                                                                margin: 2,
                                                            }}
                                                        />
                                                    </button>

                                                    {editing && (
                                                        <button
                                                            type="submit"
                                                            className="user-profile-edit"
                                                        >
                                                            <SaveIcon
                                                                color="primary"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    margin: 2,
                                                                }}
                                                            />
                                                        </button>
                                                    )}
                                                </div>

                                                <div>
                                                    <div className="form-group row">
                                                        <label
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                            for="inputPassword"
                                                            className="col-sm-3 col-form-label"
                                                        >
                                                            Name:
                                                        </label>
                                                        <div className="col-auto">
                                                            {editing ? (
                                                                <input
                                                                    style={{
                                                                        padding: 15,
                                                                    }}
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    id="inputPassword"
                                                                    value={
                                                                        formik
                                                                            .values
                                                                            .firstname
                                                                    }
                                                                    name="firstname"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        getInputValue(
                                                                            event
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                <span className="mr-2">
                                                                    {
                                                                        user.firstname
                                                                    }
                                                                </span>
                                                            )}

                                                            {editing &&
                                                                formik.errors &&
                                                                formik.errors
                                                                    .firstname && (
                                                                    <div
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            {
                                                                                formik
                                                                                    .errors
                                                                                    .firstname
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                )}

                                                            {editing ? (
                                                                <input
                                                                    style={{
                                                                        padding: 15,
                                                                    }}
                                                                    type="text"
                                                                    className="form-control form-control-sm my-2"
                                                                    id="inputPassword"
                                                                    value={
                                                                        formik
                                                                            .values
                                                                            .lastname
                                                                    }
                                                                    name="lastname"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        getInputValue(
                                                                            event
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                <span>
                                                                    {
                                                                        user.lastname
                                                                    }
                                                                </span>
                                                            )}

                                                            {editing &&
                                                                formik.errors &&
                                                                formik.errors
                                                                    .lastname && (
                                                                    <div
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            {
                                                                                formik
                                                                                    .errors
                                                                                    .lastname
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                            for="inputEmail"
                                                            className="col-sm-3 col-form-label"
                                                        >
                                                            Email:
                                                        </label>
                                                        <div className="col-auto">
                                                            {editing ? (
                                                                <input
                                                                    style={{
                                                                        padding: 15,
                                                                    }}
                                                                    type="email"
                                                                    className="form-control form-control-sm"
                                                                    id="inputEmail"
                                                                    value={
                                                                        formik
                                                                            .values
                                                                            .email
                                                                    }
                                                                    name="email"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        getInputValue(
                                                                            event
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                <span>
                                                                    {user.email}
                                                                </span>
                                                            )}

                                                            {editing &&
                                                                formik.errors &&
                                                                formik.errors
                                                                    .email && (
                                                                    <div
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            {
                                                                                formik
                                                                                    .errors
                                                                                    .email
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                            for="inputPhone"
                                                            className="col-sm-3 col-form-label"
                                                        >
                                                            Phone:
                                                        </label>
                                                        <div className="col-auto">
                                                            {editing ? (
                                                                <input
                                                                    style={{
                                                                        padding: 15,
                                                                    }}
                                                                    type="phone"
                                                                    className="form-control form-control-sm"
                                                                    id="inputPhone"
                                                                    value={
                                                                        formik
                                                                            .values
                                                                            .phone
                                                                    }
                                                                    name="phone"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        getInputValue(
                                                                            event
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                <span>
                                                                    {user.phone}
                                                                </span>
                                                            )}
                                                            {editing &&
                                                                formik.errors &&
                                                                formik.errors
                                                                    .phone && (
                                                                    <div
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            {
                                                                                formik
                                                                                    .errors
                                                                                    .phone
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label
                                                            style={{
                                                                padding: 0,
                                                            }}
                                                            for="inputPhone"
                                                            className="col-sm-3 col-form-label"
                                                        >
                                                            Default Card:
                                                        </label>
                                                        <div className="col-auto">
                                                            {user.default_card_id !==
                                                                null && (
                                                                <p className="m-0">
                                                                    ************
                                                                    {
                                                                        user.default_card
                                                                    }
                                                                </p>
                                                            )}
                                                            <Link
                                                                to="/profile/cards"
                                                                style={{
                                                                    fontSize: 13,
                                                                    textDecoration:
                                                                        "underline",
                                                                }}
                                                            >
                                                                {user.default_card_id ===
                                                                null
                                                                    ? "Add Card"
                                                                    : "Manage Cards"}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="col-md-3">
                                <div className="card_wrapper profile-info-box mb-3">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <img
                                                src={CalendarImg}
                                                alt=""
                                                className="w-100"
                                            />
                                            {/*<h3>{get(user.member_ship, "name", "")}</h3>*/}

                                            <h3>
                                                {Object.hasOwn(
                                                    user,
                                                    "member_ship"
                                                )
                                                    ? get(
                                                          user.member_ship,
                                                          "name",
                                                          ""
                                                      )
                                                    : get(
                                                          membership,
                                                          "name",
                                                          ""
                                                      )}
                                            </h3>
                                        </div>
                                        <h2>{get(user, "total_points", "")}</h2>
                                    </div>
                                    <div className="">
                                        <button
                                            type="button"
                                            className="btn btn-sm"
                                            onClick={() =>
                                                _handleClick(true, "")
                                            }
                                        >
                                            Points
                                        </button>
                                    </div>
                                </div>
                                <div className="card_wrapper profile-info-box">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <img
                                                src={CouponImg}
                                                alt=""
                                                className="w-100"
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            className="btn btn-sm"
                                            onClick={() =>
                                                _handleClick("coupons", user.id)
                                            }
                                        >
                                            Coupons
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div
                                    className="card_wrapper profile-info-box mb-3"
                                    onClick={() => _handleClick(false, "")}
                                >
                                    <div className="row">
                                        <div className="col-md-5">
                                            <img
                                                src={StarImg}
                                                alt=""
                                                className="w-100"
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            className="btn btn-sm"
                                            onClick={() =>
                                                _handleClick(true, "")
                                            }
                                        >
                                            Bookings
                                        </button>
                                    </div>
                                </div>

                                <div className="card_wrapper profile-info-box">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <img
                                                src={InvoiceImg}
                                                alt=""
                                                className="w-100"
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            className="btn btn-sm"
                                            onClick={() =>
                                                _handleClick(
                                                    "invoices",
                                                    user.id
                                                )
                                            }
                                        >
                                            Invoices
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            {showTable === "" && (
                                <>
                                    {pointsLoading ? (
                                        <div className="col-md-11 my-5">
                                            <Skeleton
                                                variant="rect"
                                                width={300}
                                                height={35}
                                                style={{ marginBottom: 5 }}
                                            />
                                            <Skeleton
                                                variant="rect"
                                                height={50}
                                                style={{ marginBottom: 5 }}
                                            />
                                            <Skeleton
                                                variant="rect"
                                                height={150}
                                                style={{ marginBottom: 5 }}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            ref={bookingTableRef}
                                            className="col-md-11"
                                            style={{ marginTop: "20px" }}
                                        >
                                            {!clicked ? (
                                                <div className="card_wrapper">
                                                    <BookedHistory />
                                                </div>
                                            ) : (
                                                <div className="card_wrapper">
                                                    {isAdmin && (
                                                        <div className="mb-3">
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm"
                                                                onClick={() => {
                                                                    setShowForm(
                                                                        "points"
                                                                    );
                                                                    setIsOpen(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                Grant Points
                                                            </button>
                                                        </div>
                                                    )}

                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">
                                                                    Reference ID
                                                                </th>
                                                                <th scope="col">
                                                                    Points
                                                                </th>
                                                                <th scope="col">
                                                                    Reason
                                                                </th>
                                                                <th scope="col">
                                                                    Gained At
                                                                </th>
                                                                <th scope="col">
                                                                    Actions
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {points &&
                                                                points.map(
                                                                    (
                                                                        point,
                                                                        index
                                                                    ) => (
                                                                        <tr
                                                                            key={
                                                                                point.id
                                                                            }
                                                                        >
                                                                            <td
                                                                                onClick={() =>
                                                                                    point.invoice_id &&
                                                                                    goToDetails(
                                                                                        point.invoice_id
                                                                                    )
                                                                                }
                                                                            >
                                                                                {point.invoice_id
                                                                                    ? point.invoice_id
                                                                                    : "N/A"}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    point.points
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    point.reason
                                                                                }

                                                                                {isAdmin &&
                                                                                    point.granted_by &&
                                                                                    point.admin && (
                                                                                        <>
                                                                                            -
                                                                                            Granted
                                                                                            By
                                                                                            <strong className="pl-1">
                                                                                                {point
                                                                                                    .admin
                                                                                                    .firstname +
                                                                                                    " " +
                                                                                                    point
                                                                                                        .admin
                                                                                                        .lastname}
                                                                                            </strong>
                                                                                        </>
                                                                                    )}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    point.updated_at
                                                                                }
                                                                            </td>

                                                                            <td>
                                                                                <button
                                                                                    style={{
                                                                                        marginTop: 0,
                                                                                    }}
                                                                                    disabled={
                                                                                        !point.invoice_id &&
                                                                                        true
                                                                                    }
                                                                                    onClick={() =>
                                                                                        goToDetails(
                                                                                            point.invoice_id
                                                                                        )
                                                                                    }
                                                                                    type="button"
                                                                                    rel="tooltip"
                                                                                    className="btn btn-info btn-link btn-just-icon btn-sm"
                                                                                    data-original-title=""
                                                                                    title="View Booking"
                                                                                >
                                                                                    <i className="material-icons">
                                                                                        description
                                                                                    </i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                )}
                                                        </tbody>
                                                    </table>

                                                    <div className="d-flex justify-content-center my-3">
                                                        {paginationDetail &&
                                                            paginationDetail.links && (
                                                                <PaginationComponent
                                                                    data={
                                                                        paginationDetail
                                                                    }
                                                                    setCurrentPage={(
                                                                        page
                                                                    ) =>
                                                                        fetchUserPoints(
                                                                            page
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </>
                            )}

                            {showTable === "coupons" && (
                                <>
                                    {couponsLoading ? (
                                        <div className="col-md-11 my-5">
                                            <Skeleton
                                                variant="rect"
                                                width={300}
                                                height={35}
                                                style={{ marginBottom: 5 }}
                                            />
                                            <Skeleton
                                                variant="rect"
                                                height={50}
                                                style={{ marginBottom: 5 }}
                                            />
                                            <Skeleton
                                                variant="rect"
                                                height={150}
                                                style={{ marginBottom: 5 }}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            ref={bookingTableRef}
                                            className="col-md-11"
                                            style={{ marginTop: "20px" }}
                                        >
                                            <div className="card_wrapper">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                #
                                                            </th>
                                                            <th scope="col">
                                                                Code
                                                            </th>
                                                            <th scope="col">
                                                                Title
                                                            </th>
                                                            <th scope="col">
                                                                Offer (%)
                                                            </th>
                                                            <th scope="col">
                                                                Max No Of use
                                                            </th>
                                                            {/* <th scope="col">
                                                            Current Total use
                                                        </th>*/}
                                                            <th scope="col">
                                                                Expire At
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {coupons &&
                                                            coupons.map(
                                                                (
                                                                    coupon,
                                                                    index
                                                                ) => (
                                                                    <tr
                                                                        key={
                                                                            coupon.id
                                                                        }
                                                                    >
                                                                        <th scope="row">
                                                                            {index +
                                                                                1}
                                                                        </th>
                                                                        <td>
                                                                            {
                                                                                coupon.code
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                coupon.title
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                coupon.off_percentage
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                coupon.max_no_of_use
                                                                            }
                                                                        </td>
                                                                        {/*<td>
                                                                        {
                                                                            coupon.no_of_used
                                                                        }
                                                                    </td>*/}
                                                                        <td>
                                                                            {
                                                                                coupon.coupon_expire
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}

                            {showTable === "invoices" && (
                                <div
                                    ref={bookingTableRef}
                                    className="col-md-11"
                                    style={{ marginTop: "20px" }}
                                >
                                    {invoiceLoading ? (
                                        <div className="col-md-11">
                                            <Skeleton
                                                variant="rect"
                                                width={300}
                                                height={35}
                                                style={{ marginBottom: 5 }}
                                            />
                                            <Skeleton
                                                variant="rect"
                                                height={50}
                                                style={{ marginBottom: 5 }}
                                            />
                                            <Skeleton
                                                variant="rect"
                                                height={150}
                                                style={{ marginBottom: 5 }}
                                            />
                                        </div>
                                    ) : (
                                        <div className="card_wrapper">
                                            {isAdmin && (
                                                <div className="mb-3">
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-info"
                                                        onClick={() => {
                                                            setShowForm(
                                                                "invoice"
                                                            );
                                                            setIsOpen(true);
                                                        }}
                                                    >
                                                        Create Invoice
                                                    </button>
                                                </div>
                                            )}

                                            <BookedInvoiceHistory />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </form>
            {isOpen && (
                <CustomDialog
                    closeDialog={() => setIsOpen(false)}
                    isNoteOpen={isOpen}
                >
                    {showForm === "points" && (
                        <GrantPointsForm
                            handleClose={() => setIsOpen(false)}
                            getFormValue={(formData) => {
                                setIsOpen(false);
                                updateUserPoints(formData);
                            }}
                            errors={[]}
                        />
                    )}

                    {showForm === "invoice" && (
                        <CreateInvoiceForm
                            handleClose={() => setIsOpen(false)}
                            getFormValue={(formData) => {
                                setIsOpen(false);
                                createNewInvoice(formData);
                            }}
                            errors={[]}
                        />
                    )}
                </CustomDialog>
            )}
        </>
    );
}
